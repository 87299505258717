import { useDispatch } from "react-redux"
import { filterLevel , resetFilter} from "../../redux/poseSlice"
import Button from 'react-bootstrap/Button';
//react-responsive library
import { useMediaQuery } from 'react-responsive'; // импортируем хук для работы с медиа-запросами

import { useTranslation } from "react-i18next"
import { useState, useEffect } from "react";


const ShowAllLevels = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ maxWidth: 600 });

    // Список уровней для внутренней логики (неизменяемый)
    const levelKeys = ["allLevels", "beginner", "middle", "advanced"];
    // Переведенные уровни для отображения
    const [translatedLevels, setTranslatedLevels] = useState([]);

    useEffect(() => {
        // Обновляем переведенные уровни при изменении языка
        setTranslatedLevels([
            t("levels.allLevels"), 
            t("levels.beginner"), 
            t("levels.middle"), 
            t("levels.advanced")
        ]);
    }, [t]); // Зависимость от хука t

    const handleFilterClick = (levelKey) => {
        if (levelKey === "allLevels") {
            dispatch(resetFilter());
        } else {
            dispatch(filterLevel(levelKey))
        }
    };

    const levelColorClass = (levelKey) => {
        switch (levelKey) {
            case "beginner": return "white";
            case "middle": return "whiteBlack";
            case "advanced": return "dark";
            default: return "dark";
        }
    };

    return (
        <div className='container-fluid d-flex flex-wrap align-items-center mx-auto my-2 '>
            {levelKeys.map((levelKey, index) => (
                <Button 
                    variant={levelKey === "allLevels" ? "dark" : "outline-dark"}
                    key={levelKey}
                    onClick={() => handleFilterClick(levelKey)}
                    className={`d-flex justify-content-center align-items-center mx-1 my-2`}>
                    {index === 0 ? (
                        <span className="px-2">{translatedLevels[index]}</span>
                    ) : (
                        <>
                            {isMobile ? (
                                <span className={`level-circle ${levelColorClass(levelKey)}`}></span>
                            ) : (
                                <>
                                    <span className={`level-circle ${levelColorClass(levelKey)}`}></span>
                                    {translatedLevels[index]}
                                </>
                            )}
                        </>
                    )}
                </Button>
            ))}
        </div>
    );
}

export default ShowAllLevels;

//                    <span className={`level-circle ${levelColorClass(level)}`}></span> {/* прилепила цветной кружочек уровня к тексту */}
//{level}
//        {[t("levels.allLevels"), t("levels.beginner"), t("levels.middle"), t("levels.advanced")].map((level) => (

/*
const ShowAllLevels = () =>{
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const levels = [t("levels.allLevels"), t("levels.beginner"), t("levels.middle"), t("levels.advanced")];
    
    const handleFilterClick = (level) => {
        if(level === "All Levels"){
            dispatch(resetFilter());
        }
        else {
        dispatch(filterLevel(level))
        }
    };

    //функция возврата css стиля цвета кружочка рядом с текстом для слов на кнопках
    const levelColorClass = (level) => {
        switch(level) {
            case "beginner": return "white";
            case "middle": return "whiteBlack";
            case "advanced": return "dark";
            default: return "dark";
        }
    };

    // определяем, является ли экран мобильным
    const isMobile = useMediaQuery({ maxWidth: 600 }); 



    return(
        <div className='container-fluid d-flex flex-wrap align-items-center mx-auto my-2 '>
        {["All Levels", "beginner", "middle", "advanced"].map((level) => (
            <Button variant={level === t("levels.allLevels") ? "dark" : "outline-dark"}
                    key={level} 
                    onClick={() => handleFilterClick(level)}
                    className={`d-flex justify-content-center align-items-center mx-1 my-2 `}>
                    {level === t("levels.allLevels") ? (
                        <span className="px-2">{level}</span> // Просто отображаем текст для "All Levels"
                        ) : (
                        <>
                            {isMobile ? (
                                <span className={`level-circle ${levelColorClass(level)}`}></span>
                            ) : (
                                <>
                                    <span className={`level-circle ${levelColorClass(level)}`}></span>
                                    {level}
                                </>
                            )}
                        </>
                    )}            
            </Button>
        ))}
    </div> 
    )
}
export default ShowAllLevels

*/

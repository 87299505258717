import { useNavigate, useParams } from "react-router";
import { data } from "../data" ;
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import { useTranslation } from 'react-i18next'; // Импортируем хук для перевода



function AboutYogaAsana( {asana} ){
    const { t } = useTranslation(); // Используем хук для получения функции перевода
    const navigate = useNavigate();
    const { title } = useParams();
    

    //функция возврата css стиля цвета кружочка рядом с текстом для слов на кнопках
    const levelColorClass = (level) => {
        switch(level) {
            case "beginner": return "white";
            case "middle": return "whiteBlack";
            case "advanced": return "dark";
            default: return "dark";
        }
    };
    


    return(
        
        <div className="container mt-5" >          
            { data.filter((item) => item.title === title).map((elem, index) => {
                return(
                    <div key={index}>
                        <Button onClick={ () => navigate(-1)} variant="outline-dark">
                        {t("homepage.goBackButton")}
                        </Button>
                        <div className="text-center mt-3">
                            <h3 className="FontSecond"> {t(`${elem.id}AsanaName`, elem.asanasName)} </h3>
                            <h3 className="FONT"> {elem.asanasNameSanskrit} </h3>
                        </div>

                        <div className="PictureAndDescription mt-5">
                            <Image src={elem.img} alt="asanaPicture" fluid/>
                            <div className="LevelAndUl">
                                <div className="levelMargin">
                                    <span> <span className={`level-circle ${levelColorClass(elem.level)}`}></span>{t(`levels.${elem.level}`)} {t("levels.level")} </span>
                                </div>
                                
                            <ul> 
                                {elem.instructions.map((instruction, index) => {
                                    // Создаем уникальный ключ для каждой инструкции, включая id асаны (elem.id)
                                    const translationKey = `${elem.id}Instructions.${index}`;
                                    return (
                                        <li key={index}>{t(translationKey)}</li>
                                    );
                                })}
                            </ul>    
                            <div> 
                                {elem.additionalImg && (
                                    <Image src={elem.additionalImg} alt="additional" width="200px"  />
                                )}
                                    
                            </div>    
                            </div>
                        </div>
                        <p className="textDescription ">{t(`${elem.id}Description`, elem.description)} </p>
            </div>
                )
            })}
        </div>
    )
}
export default AboutYogaAsana;



/**
 import { useNavigate, useParams } from "react-router";
import { data } from "../data" ;
import Button from 'react-bootstrap/Button';



function AboutYogaAsana( {asana} ){

    const navigate = useNavigate();
    const { title } = useParams();


    return(
        
        <div>
            

            { data.filter((item) => item.title === title).map((elem, index) => {
                
                return(
                    <div key={index}>
                        <Button 
                            onClick={ () => navigate(-1)}
                            variant="dark">
                        Go Back
                        </Button>

                        <h3> {elem.asanasName} </h3>
                        <p> {elem.asanasNameRus} </p>
                        <p> {elem.asanasNameSanskrit} </p>
                        <img 
                            src={elem.img}
                            alt="product"
                            />
                        <p> {elem.type} </p>
                        <p> {elem.level} </p>
                        <p> {elem.description} </p>
                        <ul>
                            {elem.instructions.map((instruction, index) =>{
                                return(
                                    <li key={index} > {instruction} </li>
                                )
                            })}
                        </ul>
                    </div>
                )
            })}
        </div>
    )
}
export default AboutYogaAsana;
 */
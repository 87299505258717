import { useSelector } from "react-redux";
import { getLessonPose } from "../../redux/lessonSlice";

const LessonLength = () => {
    const lessonPose = useSelector(getLessonPose)
    return(
        <div>
            <p >{lessonPose.length} </p>
        </div>
    )
}
export default LessonLength;
import { PDFDownloadLink } from "@react-pdf/renderer";
import StuctureLessonPDF from "./StructureLessonPDF";

import { BsFiletypePdf } from "react-icons/bs";

//react-responsive library
import { useMediaQuery } from 'react-responsive'; // импортируем хук для работы с медиа-запросами

//translation
import { useTranslation } from "react-i18next"



const LessonPDFDownload = ({ lessonPose }) => {

    //для того, чтобы формат сохраненного файла был таким "YogaGirlsLessonPlan_гггг-мм-дд.pdf".
    const currentDate = new Date() //<-- получаем текущую дату
    const fornattedDate = currentDate.toISOString().split("T")[0]; //<--Форматируем текущую дату в строку YYYY-MM-DD
    const fileName = `YogaGirlsLessonPlan_${fornattedDate}.pdf`;

    const isMobile = useMediaQuery({ maxWidth: 850 }); // определяем, является ли экран мобильным   

    const { t } = useTranslation()

    
    return (
            <PDFDownloadLink
                document={<StuctureLessonPDF lessonPose={lessonPose}   />}
                fileName={fileName}
                style={{ textDecoration: 'none', color: "#fff" }}
            >
            {isMobile ?  <BsFiletypePdf /> : t("lesson.pdfButton") }
            </PDFDownloadLink>
    );
}

export default LessonPDFDownload;
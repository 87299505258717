import { Link } from 'react-router-dom';

import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { getTotalTime, getLessonPose } from "../../redux/lessonSlice"

import AuthenticatedNavLink from '../UserAccount/AuthenticatedNavLink';

//react-responsive library
import { useMediaQuery } from 'react-responsive'; // импортируем хук для работы с медиа-запросами
import { BsGrid ,  BsPlayCircle, BsPersonCircle } from 'react-icons/bs'; // импортируем иконку
import { TbDragDrop } from "react-icons/tb";


import { useTranslation } from "react-i18next"



function TabsYG() {
    const totalTime = useSelector(getTotalTime);
    const lessonPose = useSelector(getLessonPose)

    const isMobile = useMediaQuery({ maxWidth: 850 }); // определяем, является ли экран мобильным

    const { t } = useTranslation()


    return (
        <div className=' fixed-bottom-mobile'  >
        <Nav variant="tabs " defaultActiveKey="/" className="nav-fill bg-light" >
            <Nav.Item  >
                <NavLink as={Link} to="/searchasana" className={({ isActive }) => "nav-link" + (isActive ? " active" : "") } >
                    {isMobile ? <BsGrid /> : t("tabsYG.asanaButton") }
                </NavLink>
            </Nav.Item>
            <Nav.Item >
                <NavLink as={Link} to="/lesson" className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                    {isMobile ? <><TbDragDrop style={{ width: 20, height: 20 }} /> {lessonPose.length} </> : <> {t("tabsYG.yourLesson")} {lessonPose.length} {t("tabsYG.asana")}</>}
                </NavLink>
            </Nav.Item>
            <Nav.Item >
                <NavLink as={Link} to="/play" className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                    {isMobile ? <> <BsPlayCircle /> {totalTime} </> : <> {t("tabsYG.playLesson")} {totalTime} {t("tabsYG.min")}</>} 
                </NavLink>
            </Nav.Item>
            <Nav.Item >
                <AuthenticatedNavLink to="/useraccount" className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                    {isMobile ? <BsPersonCircle /> : t("tabsYG.yogaboard")}
                </AuthenticatedNavLink>
            </Nav.Item>
        </Nav>

        </div>
    );
}

export default TabsYG;


import { useEffect } from "react"
import { BsArrowUpCircle } from "react-icons/bs";

const { useState } = require("react")

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false)

    // Функция для прокрутки наверх
    const ScrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    //Обработчик события прокрутки
    useEffect(() =>{
        const toggleVisibility = () =>{
            if (window.scrollY > 300) {
                setIsVisible(true);
            }
            else {
                setIsVisible(false)
            }
        }

        window.addEventListener("scroll" , toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, [])

    return(
        <div>
                <button
                    className={`scrollToTopButton ${isVisible ? 'show' : ''}`} // класс show, если isVisible === true
                    onClick={ScrollToTop}
                    style={{ display: isVisible ? 'block' : 'none' }} // Альтернативный способ управления видимостью
                >
                <BsArrowUpCircle style={{ width: 30, height: 140 }}/>
                </button>
        </div>
    )
}

export default ScrollToTopButton;
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"
import Footer from "./Footer";
import { HelmetProvider, Helmet } from 'react-helmet-async';
//import Donate from "./Donate";




const Membership = () =>{
    const { t, i18n } = useTranslation()

    return(
        <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
            <HelmetProvider>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('metatags.membership.membershipTitle')}</title>
                <meta name="description" content={t('metatags.membership.description')} />
            </Helmet>
            </HelmetProvider>

            <div style={{ flex: "1" }}>

                <div className="mt-5 " >
                    <div className="pricing-header p-3 pb-md-5 mx-auto text-center mb-5">
                        <h2 className="display-4 fw-normal text-body-emphasis mb-5 FontOne">{t("membership.membership")}</h2>
                        <p className="fs-5  container FreeContentColor FontTwo">{t("membership.adressingToAll")}</p>
                        {/* <Donate /> */}
                        
                        
                    </div>

                    <div className="container DisplayNoneForThisContent">
                        <div className="row row-cols-1 row-cols-md-3 mb-3 text-center ">
                            <div className="col">
                                <div className="card mb-4 rounded-3 shadow">
                                <div className="card-header py-3">
                                    <h4 className="my-0 fw-normal FontTwo">{t("membership.free")}</h4>
                                </div>
                                <div className="card-body">
                                    <h1 className="card-title pricing-card-title">0€<small className="text-body-secondary fw-light">/{t("membership.period1")}</small></h1>
                                    <ul className="list-unstyled mt-3 mb-4">
                                    <li>{t("membership.features1")}</li>
                                    <li>{t("membership.features2")}</li>
                                    <li>{t("membership.features3")}</li>
                                    </ul>
                                    <Link to="/searchasana">
                                        <button type="button" className="w-100 btn btn-lg btn-outline-dark FontTwo">{t("membership.membershipButton2")}</button>
                                    </Link>
                                </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-4 rounded-3 shadow">
                                <div className="card-header py-3">
                                    <h4 className="my-0 fw-normal FontTwo">{t("membership.nameOfPricingPlans")}</h4>
                                </div>
                                <div className="card-body">
                                    <h1 className="card-title pricing-card-title">5€<small className="text-body-secondary fw-light">/{t("membership.period1")}</small></h1>
                                    <ul className="list-unstyled mt-3 mb-4">
                                    <li>{t("membership.features6")}</li>
                                    <li>❤️ {t("membership.features5")}</li>
                                    <li>{t("membership.features4")}</li>
                                    </ul>
                                    <Link to="/searchasana" >
                                        <button type="button" className="w-100 btn btn-lg btn-dark MembershipColor FontTwo">{t("membership.membershipButton1")}</button>
                                    </Link>
                                </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-4 rounded-3 shadow  " >
                                <div className="card-header py-3 text-bg  MembershipColor ">
                                    <h4 className="my-0 fw-normal MembershipFontColor FontTwo ">{t("membership.nameOfPricingPlans")}</h4>
                                </div>
                                <div className="card-body">
                                    <h1 className="card-title pricing-card-title">50€<small className="text-body-secondary fw-light">/{t("membership.period2")}</small></h1>
                                    <ul className="list-unstyled mt-3 mb-4">
                                    <li>{t("membership.features6")}</li>
                                    <li>❤️ {t("membership.features5")}</li>
                                    <li>{t("membership.features4")}</li>
                                    </ul>
                                    <Link to="/searchasana" >
                                        <button type="button" className="w-100 btn btn-lg btn-dark MembershipColor FontTwo">{t("membership.membershipButton1")}</button>
                                    </Link>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer/>
        </div>

    )
}
export default Membership;
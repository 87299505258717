import { useEffect, useState } from 'react';
import { data } from "../../data"
import "./player.css"
import { useSelector } from 'react-redux';
import { getLessonPose } from "../../redux/lessonSlice"
import Progressbar from "./Progressbar"
import Image from 'react-bootstrap/Image';
import AsanaSound from "../../myAssets/AsanaSound.mp3"


//navigation buttons for slider
import { BsChevronCompactRight } from "react-icons/bs"
import { BsChevronCompactLeft } from "react-icons/bs";
import { BsPause } from "react-icons/bs";
import { BsPlay } from "react-icons/bs";

//sound buttons
import { BiSolidVolumeFull } from "react-icons/bi";
import { BiSolidVolumeMute } from "react-icons/bi";

//import react-circular-progressbar library
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ScreenWakeLock from './ScreenWakeLock';

import { useTranslation } from 'react-i18next'; // Импортируем хук для перевода
import TabsYG from '../Website/TabsYG';
import { HelmetProvider, Helmet } from 'react-helmet-async';








const Player = () => {
    const lessonPose = useSelector(getLessonPose);
    const [currentPoseIndex, setCurrentPoseIndex] = useState(0); // Текущий индекс позы
    const [timeLeft, setTimeLeft] = useState(0); // Измененное состояние для отслеживания оставшегося времени
    const [maxDuration, setMaxDuration] = useState(0); // Для хранения максимального времени слайда
    const [isPlaying, setIsPlaying] = useState(false); // false означает пауза, true - воспроизведение
    const [isSoundOn, setIsSoundOn] = useState(false); //false означает звук при переключении поз не включен, true - включен
    const { t, i18n } = useTranslation(); // Используем хук для получения функции перевода




    useEffect(() => {
        const currentPose = lessonPose[currentPoseIndex];
        const currentSlideDuration = currentPose ? currentPose.duration : 0;
        setTimeLeft(currentSlideDuration);
        setMaxDuration(currentSlideDuration);

        let timer = null;
        if (isPlaying) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 1) {
                        clearInterval(timer);
                        setCurrentPoseIndex((prevIndex) => {
                            let newIndex = prevIndex + 1;
                            if (newIndex >= lessonPose.length) {
                                newIndex = 0;
                                setIsPlaying(false);
                            }
                            return newIndex;
                        });
                        return currentPose ? currentPose.duration : 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }

        return () => {
            if (timer) clearInterval(timer);
        };
    }, [currentPoseIndex, lessonPose, isPlaying]);

    const togglePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    // Функция для переключения на предыдущую позу
    const previousPose = () => {
        setCurrentPoseIndex((current) => {
            let newIndex = current - 1;
            if (newIndex < 0) {
                newIndex = lessonPose.length - 1; // Переход к последнему элементу, если индекс станет меньше 0
            }
            return newIndex;
        });
    };

    // Функция для переключения на следующую позу
    const nextPose = () => {
        setCurrentPoseIndex((current) => {
            let newIndex = current + 1;
            if (newIndex >= lessonPose.length) {
                newIndex = 0; // Возвращение к первому элементу, если индекс превысит количество элементов
            }
            return newIndex;
        });
    };

    // Находим текущую позу в данных по ID
    const currentPose = lessonPose[currentPoseIndex];
    const asanaInPlayer = currentPose ? data.find(asana => asana.id === currentPose.poseId) : null;

    //Для вкл/выкл звука
    const handleSoundToggle = () => {
        setIsSoundOn(isSoundOn => !isSoundOn);
    };

    useEffect(() => {
        // Создаем объект аудио
        const sound = new Audio(AsanaSound);

        // Воспроизведение звука, если звук включен
        if (isSoundOn) {
            sound.play().catch(error => console.error("Error playing the sound:", error));
        }
    
        // Очистка для предотвращения утечек памяти
        return () => sound.pause(); // Пауза или остановка звука, когда компонент демонтируется или при переключении
    }, [currentPoseIndex, isSoundOn]); // Зависимости эффекта


    return (
        <div>
            <HelmetProvider>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('metatags.player.playerTitle')}</title>
                <meta name="description" content={t('metatags.player.description')} />
            </Helmet>
            </HelmetProvider>

            <TabsYG />

        <div className='PlayerControls'>
            <ScreenWakeLock />

            <div className='TotalTimeAndSoundButton'>
                <button onClick={handleSoundToggle} className='SoundButton' >
                {isSoundOn ? <BiSolidVolumeFull style={{ width: 30, height: 30 }} /> : <BiSolidVolumeMute style={{ width: 30, height: 30 }} />}
                </button>
            </div>


            <div className='Player'>
                <div className='NavigationButton'>
                    <BsChevronCompactLeft onClick={previousPose} style={{ width: 50, height: 50 }}/>
                </div>

                {asanaInPlayer && ( // Проверяем, что asanaInPlayer не null перед отображением контента
                <div className='CardAndName'>
                    <p className='PlayerP FontSecond'> {t(`${asanaInPlayer.id}AsanaName`, asanaInPlayer.asanasName)} </p>
                    <p className='PlayerP FONT'> {asanaInPlayer.asanasNameSanskrit} </p>
                    <div className='PlayerCards shadow p-3 mb-5 bg-white rounded'>
                        <Image src={asanaInPlayer.img} alt={`Slide ${currentPoseIndex}`} fluid />
                        <div className='CircularProgressContainer' >
                            <CircularProgressbar 
                                value={timeLeft} 
                                maxValue={maxDuration}
                                text={`${timeLeft}`} 
                                strokeWidth={5}
                                styles={buildStyles({
                                    backgroundColor: "#76797B",
                                    textSize: '40px',
                                    textColor: "#332D2D",
                                    pathColor: "#D3D3D3",
                                    trailColor: "transparent"
                                })}
                            />
                        </div>  

                    </div>

                    <Progressbar currentIndex={currentPoseIndex} totalPoses={lessonPose.length} />

                </div>
                )}

                <div className='NavigationButton' >
                    <BsChevronCompactRight onClick={nextPose} style={{ width: 50, height: 50 }}/>
                </div>

            </div>


            <div className='PlayStopPauseButtons'>
                <button onClick={togglePlayPause} className='PlayPauseButton'>
                    {isPlaying ? <BsPause style={{ width: 100, height: 100 }} /> : <BsPlay style={{ width: 100, height: 100 }} />}
                </button>
            </div>

        </div>
        </div>
    );
};
export default Player

//import { getLessonPose, getTotalTime } from "../../redux/lessonSlice"
//    const totalTime = useSelector(getTotalTime)
//                <p> {totalTime} Min.</p>



import LessonPose from "./LessonPose"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux";
import { getTotalTime, getLessonPose, clearLesson, reorderLessonPose, setLessonPoseFromLocalStorage } from "../../redux/lessonSlice"
import { Button } from "react-bootstrap"
import { useEffect } from "react";
import LessonPDFDownload from "../LessonPDF/LessonPDFDownload";

//icons
import { BsTrash3 } from "react-icons/bs";
import { TbDragDrop } from "react-icons/tb";

//dnd-kit
import { DndContext,
        closestCenter,
        MouseSensor,
        KeyboardSensor,
        PointerSensor,
        useSensor,
        TouchSensor,
        useSensors,
        } 
        from '@dnd-kit/core';
import { arrayMove,
        SortableContext,
        horizontalListSortingStrategy } 
        from '@dnd-kit/sortable';
import { SortableItem } from "./SortableItem";
import { TouchSensorOptions } from "./TouchSensorOptions";

import LessonUserForm from "./LessonUserForm";
import LessonLength from "./LessonLength";
import ScrollToTopButton from "../Website/ScrollToTopButton";
import TabsYG from "../Website/TabsYG";

//react-responsive library
import { useMediaQuery } from 'react-responsive'; // импортируем хук для работы с медиа-запросами

//translation
import { useTranslation } from "react-i18next"

import { HelmetProvider, Helmet } from 'react-helmet-async';




const Lesson = (  ) =>{
    const lessonPose = useSelector(getLessonPose)
    const dispatch = useDispatch()
    const totalTime = useSelector(getTotalTime)

        
    //dnd-kit library
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor),
        useSensor(MouseSensor),
        useSensor(TouchSensor,TouchSensorOptions)
    );
    
    //dnd-kit typical approach from documentation
    const handleDragEnd = (event) => {
        const {active, over} = event;
    
        if (over && active.id !== over.id) {
            const oldIndex = lessonPose.findIndex((pose) => String(pose.id) === String(active.id));
            const newIndex = lessonPose.findIndex((pose) => pose.id === over.id);
            dispatch(reorderLessonPose(arrayMove(lessonPose, oldIndex, newIndex)));
        }
    };

    //localStorage
    useEffect(() => {
        //console.log('Saving lesson pose to localStorage:', lessonPose);
        localStorage.setItem('lessonPose', JSON.stringify(lessonPose));
    }, [lessonPose]); // Эффект будет вызываться каждый раз, когда изменяется lessonPose
    

    useEffect(() => {
        const savedLessonPose = localStorage.getItem('lessonPose');
        if (savedLessonPose) {
            const lessonPoseArray = JSON.parse(savedLessonPose);
            //console.log('Fetched lesson pose from localStorage:', lessonPoseArray);
            dispatch(setLessonPoseFromLocalStorage(lessonPoseArray));
            }
        }, [dispatch]); //эффект будет вызываться только один раз при монтировании компонента
    

    //сохранение урока в аккаунте юзера
    const handleSaveLesson = (lessonData) =>{
        //console.log("Lesson data is saved", lessonData)
    }    

    const isMobile = useMediaQuery({ maxWidth: 850 }); // определяем, является ли экран мобильным   

    //translation
    const { t, i18n } = useTranslation()
    

    return(
        <div >
            <HelmetProvider>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('metatags.lesson.lessonTitle')}</title>
                <meta name="description" content={t('metatags.lesson.description')} />
            </Helmet>
            </HelmetProvider>

            <TabsYG />
            <div>      
                <LessonUserForm onSave={handleSaveLesson} lessonPose={lessonPose} />

                <div className="container-fluid d-flex justify-content-end  ">           
                    <Button  
                        variant="dark"
                        className="me-2">
                        <LessonPDFDownload lessonPose={lessonPose} /> 
                    </Button>

                    <Button onClick={() => dispatch(clearLesson())} 
                        variant="outline-dark">
                            {isMobile ? <BsTrash3 />: t("lesson.clearLessonButton") } 
                    </Button>

                </div>

                <div className="container-fluid YourLessonLengthTime d-flex justify-content-center mt-3 mb-3 ">
                        <TbDragDrop style={{ width: 20, height: 20 }} />
                        <p className="FONT FontMobile" > {t("lesson.yourLesson")}</p>
                        <span className="YourLessonLengthTime FontSecond FontMobile"> <LessonLength /> {t("searchAsanas.asanas100")}, </span>
                        <p className="FontSecond FontMobile"> {t("lesson.duration")} {totalTime} {t("tabsYG.min")} </p>
                </div>


                <DndContext     
                    sensors={sensors} 
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}>
            
                    <SortableContext items={lessonPose.map(pose => pose.id)} 
                                    strategy={horizontalListSortingStrategy}>
                        <div className="LessonCards">    
                        {lessonPose.map((pose) => (
                            <SortableItem key={pose.id} id={pose.id}  >
                                <LessonPose  pose={pose}  />
                            </SortableItem>
                        ))}
                        </div>
                    </SortableContext>
                </DndContext>

                <ScrollToTopButton />
            </div>
        </div>
        );
}

export default Lesson;

/*
                <div className="container-fluid d-flex justify-content-end">
                    Drag&Drop <TbDragDrop style={{ width: 30, height: 30 }} />
                </div>
 */


//тут будет все про фильтры
//Create a Redux State Slice
//в этом документе в reducers мы прописали вариант изменения начального состояния
//это просто щаблон. Он соединяет filteredCategory с другими документами
//мы обязаетельно не забываем его export!
//мы получаем доступ к состоянию вот тут state.selectedCategory = action.payload;
//это настройки прописывания. Это не мы придумали.

import { createSlice } from '@reduxjs/toolkit'

export const poseSlice = createSlice( {
    name: "yogaPoses",
    initialState: {
        selectedLevels: "All Levels"
    },
    reducers: {
        filterLevel: (state, action) => {
            state.selectedLevels = action.payload;
        },
        resetFilter: (state) => {
            state.selectedLevels = "All Levels"
        }
    }
} )

export const getSelectedLevel = state => state.yogaPoses.selectedLevels;
export const {filterLevel , resetFilter } = poseSlice.actions;
export default poseSlice.reducer
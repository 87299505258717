import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap"
import RandomPoseImage from "./RandomPoseImage";
import { data } from '../../data'
import { Link } from "react-router-dom";

//для форматирования даты
import moment from 'moment';
//react-responsive library
import { useMediaQuery } from 'react-responsive'; // импортируем хук для работы с медиа-запросами
//иконки
import { BsTrash , BsPencil} from 'react-icons/bs';

import { useDispatch } from "react-redux";
import { setSavedLessonPoses } from "../../redux/lessonSlice";
import TabsYG from "../Website/TabsYG";

import { useTranslation } from "react-i18next"




const UserAccount = ( {saveLesson} ) =>{ 
    const { user, isAuthenticated } = useAuth0();
    //для отображения уроков пользователя
    const [lessons, setLessons] = useState([]);

    // определяем, является ли экран мобильным
    const isMobile = useMediaQuery({ maxWidth: 850 }); 


    //для отправки сохраненных поз в Lesson
    const dispatch = useDispatch();
    const handleEditLesson = (lessonPoses) => {
        //console.log(lessonPoses)
        dispatch(setSavedLessonPoses(lessonPoses));
    };

    //адентификация пользователя
    useEffect(()=>{
        if (isAuthenticated) {
            loadUserLessons(user.sub);
        }
    },[user, isAuthenticated]);
    

    //отображение сохраненных уроков пользователя по его userId
    const loadUserLessons = async (userId) => {
        try {
            //const response = await axios.get("http://localhost:4000"); //это без пользователя *старое
            //const response = await axios.get(`http://localhost:4000/${userId}`); // добавляем идентификатор пользователя к запросу
            const response = await axios.get(`https://yogagirls-backend.onrender.com/${userId}`); // добавляем идентификатор пользователя к запросу это для выложенной версии
            //console.log(response.data)
            setLessons(response.data);
        } catch (error) {
            //console.error("Ошибка при загрузке уроков:", error);
        }
    };

    //удаление уроков пользователя с учетом его userId
    const deleteUserLesson = async (_id) =>{
        await axios.delete(`https://yogagirls-backend.onrender.com/${user.sub}/deleteLesson`, { data: { _id } })
        //await axios.delete(`http://localhost:4000/${user.sub}/deleteLesson`, { data: { _id } })
        //axios.delete("http://localhost:4000/deleteLesson", { data: { _id } })
        .then((data) => { 
            //console.log(data);
            //loadUserLessons(setLessons); 
            loadUserLessons(user.sub);
        })
    }

    const { t } = useTranslation()



    return(
        isAuthenticated && (
        <div>
            <TabsYG/>
            <div className="container-fluid mt-3" >
                <p className="text-end FONT"> {t("useAccount.welcomeMessage")} {user.nickname} </p>
                <p className="text-end FONT"> {t("useAccount.email")} : {user.email}</p>
            </div>
            <h2 className="container-fluid shadow-sm p-3 mb-5 bg-white rounded FONT" > {t("useAccount.yogaBoard")} </h2>



            {lessons.map((lesson, index) => (
                <div key={index} className="container-fluid">
                    <div className="UserAccountLessonsdisplay   shadow-sm p-3 mb-5 bg-white rounded "  >
                        <div  className="col-md-auto">
                            <RandomPoseImage data={data} />
                        </div> 
                        <div className="col ms-3" >
                            <p className="FontSecond" > {lesson.name} </p>
                            <div className="col-md-auto">
                                {isMobile ? (
                                <span className={`level-circle ${lesson.level === "beginner" ? "white" : lesson.level === "middle" ? "whiteBlack" : "dark"}`} />
                                ) : (
                                <p> <span className={`level-circle ${lesson.level === "beginner" ? "white" : lesson.level === "middle" ? "whiteBlack" : "dark"}`} /> {t(`levels.${lesson.level}`)} </p>
                                )}
                                </div>
                                <p className="dateInUserAccount"> {moment(lesson.date).isValid() ? moment(lesson.date).format('DD.MM.YYYY') : ''} </p>
                        </div>
                        <Link to="/lesson" className="ms-3" >
                            <Button onClick={() => handleEditLesson(lesson.poses)} variant="dark" >{isMobile ? <BsPencil /> : t("useAccount.viewEditButton")}</Button>
                        </Link>
                        <Button onClick={() => deleteUserLesson(lesson._id)} variant="outline-dark" className="ms-1">{isMobile ? <BsTrash /> : t("useAccount.deleteButton")}</Button>
                    </div>
                </div>
            ))}
        </div>
        )
    )

}

export default UserAccount;

//            const response = await axios.get(`http://localhost:4000/${userId}`); // добавляем идентификатор пользователя к запросу
//        await axios.delete(`http://localhost:4000/${user.sub}/deleteLesson`, { data: { _id } })

import yoga1 from "./myAssets/1.png"
import yoga2 from "./myAssets/2.png"
import yoga3 from "./myAssets/3.png"
import yoga4 from "./myAssets/4.png"
import yoga5 from "./myAssets/5.png"
import yoga6 from "./myAssets/6.png"
import yoga7 from "./myAssets/7.png"
import yoga8 from "./myAssets/8.png"
import yoga9 from "./myAssets/9.png"
import yoga10 from "./myAssets/10.png"
import yoga11 from "./myAssets/11.png"
import yoga12 from "./myAssets/12.png"
import yoga13 from "./myAssets/13.png"
import yoga14 from "./myAssets/14.png"
import yoga15 from "./myAssets/15.png"
import yoga16 from "./myAssets/16.png"
import yoga17 from "./myAssets/17.png"
import yoga18 from "./myAssets/18.png"
import yoga19 from "./myAssets/19.png"
import yoga20 from "./myAssets/20.png"
import yoga21 from "./myAssets/21.png"
import yoga22 from "./myAssets/22.png"
import yoga23 from "./myAssets/23.png"
import yoga24 from "./myAssets/24.png"
import yoga25 from "./myAssets/25.png"
import yoga26 from "./myAssets/26.png"
import yoga27 from "./myAssets/27.png"
import yoga28 from "./myAssets/28.png"
import yoga29 from "./myAssets/29.png"
import yoga30 from "./myAssets/30.png"
import yoga31 from "./myAssets/31.png"
import yoga32 from "./myAssets/32.png"
import yoga33 from "./myAssets/33.png"
import yoga34 from "./myAssets/34.png"
import yoga35 from "./myAssets/35.png"
import yoga36 from "./myAssets/36.png"
import yoga37 from "./myAssets/37.png"
import yoga38 from "./myAssets/38.png"
import yoga39 from "./myAssets/39.png"
import yoga40 from "./myAssets/40.png"
import yoga41 from "./myAssets/41.png"
import yoga42 from "./myAssets/42.png"
import yoga43 from "./myAssets/43.png"
import yoga44 from "./myAssets/44.png"
import yoga45 from "./myAssets/45.png"
import yoga46 from "./myAssets/46.png"
import yoga47 from "./myAssets/47.png"
import yoga48 from "./myAssets/48.png"
import yoga49 from "./myAssets/49.png"
import yoga50 from "./myAssets/50.png"
import yoga51 from "./myAssets/51.png"
import yoga52 from "./myAssets/52.png"
import yoga53 from "./myAssets/53.png"
import yoga54 from "./myAssets/54.png"
import yoga55 from "./myAssets/55.png"
import yoga56 from "./myAssets/56.png"
import yoga57 from "./myAssets/57.png"
import yoga58 from "./myAssets/58.png"
import yoga59 from "./myAssets/59.png"
import yoga60 from "./myAssets/60.png"
import yoga61 from "./myAssets/61.png"
import yoga62 from "./myAssets/62.png"
import yoga63 from "./myAssets/63.png"
import yoga64 from "./myAssets/64.png"
import yoga65 from "./myAssets/65.png"
import yoga66 from "./myAssets/66.png"
import yoga67 from "./myAssets/67.png"
import yoga68 from "./myAssets/68.png"
import yoga69 from "./myAssets/69.png"
import yoga70 from "./myAssets/70.png"
import yoga71 from "./myAssets/71.png"
import yoga72 from "./myAssets/72.png"
import yoga73 from "./myAssets/73.png"
import yoga74 from "./myAssets/74.png"
import yoga75 from "./myAssets/75.png"
import yoga76 from "./myAssets/76.png"
import yoga77 from "./myAssets/77.png"
import yoga78 from "./myAssets/78.png"
import yoga79 from "./myAssets/79.png"
import yoga80 from "./myAssets/80.png"
import yoga81 from "./myAssets/81.png"
import yoga82 from "./myAssets/82.png"
import yoga83 from "./myAssets/83.png"
import yoga84 from "./myAssets/84.png"
import yoga85 from "./myAssets/85.png"
import yoga86 from "./myAssets/86.png"
import yoga87 from "./myAssets/87.png"
import yoga88 from "./myAssets/88.png"
import yoga89 from "./myAssets/89.png"
import yoga90 from "./myAssets/90.png"
import yoga91 from "./myAssets/91.png"
import yoga92 from "./myAssets/92.png"
import yoga93 from "./myAssets/93.png"
import yoga94 from "./myAssets/94.png"
import yoga95 from "./myAssets/95.png"
import yoga96 from "./myAssets/96.png"
import yoga97 from "./myAssets/97.png"
import yoga98 from "./myAssets/98.png"
import yoga99 from "./myAssets/99.png"
import yoga100 from "./myAssets/100.png"
import a1 from "./myAssets/add1.png"
import a3 from "./myAssets/add3.png"
import a4 from "./myAssets/add4.png"
import a5 from "./myAssets/add5.png"
import a7 from "./myAssets/add7.png"






export const data = [
    {
        id: 1,
        img: yoga1,
        asanasName:"Tree Pose",
        title:"Vriksasana_variation",
        asanasNameRus: "Поза Дерева вариация",
        asanasNameDe: "der Baum",
        asanasNameBe: "Пастава Дрэва",
        asanasNameSanskrit:"Vriksasana variation",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Врикшасана улучшает чувство баланса, повышает устойчивость, укрепляются мышцы ног, укрепляется позвоночник. На этапе освоения могут появиться сложности: не удается поставить высоко ногу, теряется баланс. Важно: стопу согнутой ноги не устанавливаем на колено опорной ноги, иначе это может привести к травме коленного сустава.",
        instructions: [
            "лобковая кость на себя",
            "плечи от ушей",
            "живот и нижние ребра вовнутрь",
            "колено согнутой ноги направлено в сторону",
            "таз на одной линии с плечами",
            "коленная чашечка опорной ноги вверх",
            "в упрощенной вариации: поставить стопу согнутой ноги на голень или пол",
            "в упрощенной вариации: ладони вместе в намасте или на талию"
            ]
    },
    {
        id: 2,
        img: yoga2,
        asanasName: "Lotus Pose",
        title:"Padmasana",
        asanasNameRus: "Поза Лотоса",
        asanasNameDe: "der Lotossitz",
        asanasNameBe: "Пастава Лотаса",
        asanasNameSanskrit:"Padmasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Падмасана одна из основных поз для дыхательных практик. Может быть сложна в удержании. Начинающим рекомендуется сохранять отстройку позы, но сгибать полностью лишь одну ногу.",
        instructions: [
            "вытягивать позвоночник вверх",
            "плечи вниз",
            "грудь направлять вверх",
            "живот и нижние ребра вовнутрь",
            "пятки в контакте с низом живота",
            "пальцы ног направлять за пределы бедер",
            "стопы уложить в место сгиба",
            "колени в пол",
            "седалищные косточки в пол",
            ]
    },
    {
        id: 3,
        img: yoga3,
        asanasName: "Tree Pose",
        title:"Vriksasana",
        asanasNameRus: "Поза Дерева",
        asanasNameDe: "der Baum",
        asanasNameBe: "Пастава Дрэва",
        asanasNameSanskrit:"Vriksasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Врикшасана улучшает чувство баланса, повышает устойчивость, укрепляются мышцы ног, укрепляется позвоночник. Важно: стопу согнутой ноги не устанавливаем на колено опорной ноги, иначе это может привести к травме коленного сустава.",
        instructions: [
            "лобковая кость на себя",
            "плечи от ушей",
            "живот и нижние ребра вовнутрь",
            "колено согнутой ноги направлено в сторону",
            "таз на одной линии с плечами",
            "коленная чашечка опорной ноги вверх",
            ]
    },

    {
        id: 4,
        img: yoga4,
        asanasName: "Tree Volcano Pose",
        title:"Vrksasana_Urdhva_Hastasana",
        asanasNameRus: "Поза Дерева Вулкана",
        asanasNameDe: "der Vulkanbaum",
        asanasNameBe: "Пастава Дрэва Вулкана",
        asanasNameSanskrit:"Vrksasana Urdhva Hastasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Врикшасана с поднятыми вверх и слегка разведенными руками улучшает чувство баланса, повышает устойчивость, укрепляются мышцы ног и рук, укрепляется позвоночник. Важно: стопу согнутой ноги не устанавливаем на колено опорной ноги, иначе это может привести к травме коленного сустава.",
        instructions: [
            "лобковая кость на себя",
            "плечи от ушей",
            "живот и нижние ребра вовнутрь",
            "колено согнутой ноги направлено в сторону",
            "таз на одной линии с плечами",
            "коленная чашечка опорной ноги вверх",
            "в вариации с руками вверх: руки вытянуты"
            ]
    },
    {
        id: 5,
        img: yoga5,
        asanasName: "Hand To Big Toe Pose II",
        title:"Utthita_Hasta_Padangusthasana_II",
        asanasNameRus: "Захват большого пальца ноги Стоя",
        asanasNameDe: "Stehende Großzehen-Haltehaltung",
        asanasNameBe: "Захоп вялікага пальца ногі Стоячы",
        asanasNameSanskrit:"Utthita Hasta Padangusthasana II",
        level: "middle",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Уттхита Хаста Падангуштхасана II укрепляет и вытягивает мышцы ног, способствует обретению эмоциональной уравновешенности.",
        instructions: [
            "плечи от ушей на одной линии с тазом",
            "живот и нижние ребра вовнутрь",
            "таз не заваливается в сторону",
            "макушка вверх",
            "колено опорной ноги вверх"
            ]
    },
    {
        id: 6,
        img: yoga6,
        asanasName: "Mountain Pose",
        title:"Tadasana",
        asanasNameRus: "Поза Горы",
        asanasNameDe: "die Bergpose",
        asanasNameBe: "Пастава Гары",
        asanasNameSanskrit:"Tadasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: a7,
        description: "Одна из ключевых и фундаментальных асан, правильное выполнение которой раскрывает принципы работы в большинстве поз и помогает почувствовать активность каждой группы мышц. Это приносит энергию, согревает тело, улучшает осанку и помогает осознать центр баланса в организме.",
        instructions: [
            "вытягивать позвоночник вверх",
            "подбородок параллельно полу",
            "плечи вниз",
            "грудь раскрыта, грудина вверх",
            "поясница плоская, не выгибать",
            "таз подкручен",
            "ягодицы активны",
            "живот подтянут",
            "лобковая кость к пупку",
            "пальцы ног раскрыты как веер",
            "большие пальцы ног рядом, пятки могут не соприкасаться",
            "коленные чашечки вверх",
            "при гиперподвижности коленных суставов не вытягивать ноги полностью, вовлекать в работу мышцы",
            "вес тела переносить назад на пятки, наблюдать контакт стоп с поверхностью",
            ]

    },
    {
        id: 7,
        img: yoga7,
        asanasName: "Chair Pose",
        title:"Utkatasana",
        asanasNameRus: "Поза Cтула",
        asanasNameDe: "die Stuhlpose",
        asanasNameBe: "Пастава Cтула",
        asanasNameSanskrit:"Utkatasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Существует несколько вариаций Позы Стула. Меняется положение рук, ног, глубина приседа. Во всех вариациях важно сохранять базовые отстройки позы: положение таза и плеч.",
        instructions: [
            "плечи вниз",
            "ладони вместе, пальцы не переплетать",
            "взгляд на большие пальцы рук",
            "намерение соединить запястья",
            "локти вытянуты",
            "не зажимать шею, поддерживать трапециевидными мышцами",
            "внешнее вращение плеч - направление трицепса вперед",
            "грудь раскрыта, грудина вверх",
            "нижние ребра вовнутрь",
            "поясница плоская, не выгибать",
            "живот вовнутрь",
            "таз подкручен",
            "внутренние поверхности бедер направлять друг к другу",
            "лобковая кость к пупку",
            "копчик направлен в пол",
            "колени четко вперед и не выходят за линию стоп",
            "стопами отталкиваемся от пола",
            "основной вес на пятках",
        ]


    },
    {
        id: 8,
        img: yoga8,
        asanasName: "Palm Pose",
        title:"Urdhva_Hastasana",
        asanasNameRus: "Поза Пальмы",
        asanasNameDe: "die Palmenpose",
        asanasNameBe: "Пастава Пальмы",
        asanasNameSanskrit:"Urdhva Hastasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: a1,
        description: "Отстройка схожа с тадасаной, однако в этой позе появляется активная работа плечевого отдела, что способствует улучшению его подвижности.",
        instructions: [
            "вытяжение от стоп до кончиков пальцев на ногах",
            "ладони вместе, но не переплетать пальцы",
            "взгляд на большие пальцы",
            "не зажимать шею, поддерживать трапециевидными мышцами",
            "внешнее вращение плеч - направление трицепса вперед",
            "грудь раскрыта, грудина вверх",
            "плечи вниз",
            "нижние ребра вовнутрь",
            "таз подкручен",
            "ягодицы активны",
            "поясница плоская, не выгибать",
            "живот вовнутрь",
            "лобковая кость к пупку",
            "квадрицепсы активны",
            "соединять внутренние поверхности бедер",
            "коленные чашечки вверх",
            "стопами отталкиваться от пола, способствуя вытяжению"
        ]

    },
    {
        id: 9,
        img: yoga9,
        asanasName: "Volcano Pose",
        title:"Tadasana_Urdhva_Hastasana",
        asanasNameRus: "Поза Вулкана",
        asanasNameDe: "die Vulkanpose",
        asanasNameBe: "Пастава Вулкана",
        asanasNameSanskrit:"Tadasana Urdhva Hastasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Отстройка тадасаны, однако в этой позе появляется активная работа плечевого отдела, что способствует улучшению его подвижности. Если у практикующего нет возможности соединить руки, то просто вытяните их вверх.",
        instructions: [
            "вытяжение от стоп до кончиков пальцев на ногах",
            "плечи вниз",
            "нижние ребра вовнутрь",
            "таз подкручен",
            "ягодицы активны",
            "поясница плоская, не выгибать",
            "живот вовнутрь",
            "лобковая кость к пупку",
            "квадрицепсы активны",
            "соединять внутренние поверхности бедер",
            "коленные чашечки вверх",
            "стопами отталкиваться от пола, способствуя вытяжению"
        ]
    },
    {
        id: 10,
        img: yoga10,
        asanasName: "Handstand",
        title:"Adho_Mukha_Vrksasana",
        asanasNameRus: "Стойка На Руках",
        asanasNameDe: "der Handstand",
        asanasNameBe: "Стойка На Руках",
        asanasNameSanskrit:"Adho Mukha Vrksasana",
        level: "advanced",
        type: "standing asana",
        duration: 30,
        additionalImg: a4,
        description: "Для подготовки к Адхо Мукха Вркшасана важно уже иметь подготовленное тело, способность удержать весь вес тела на ладонях, должен отсутствовать страх падения и паники. Существует много нюансов в выполнении этой асаны. Разные инструкторы учат различным приемам. Важно соблюдать базовые правила отстройки, чтобы снизить риск травмы.",
        instructions: [
            "стремиться выстроить тело в одну линию запястья-плечи-груль-таз-стопы",
            "внешний край ладони под плечом",
            "пальцы активны и раскрыты",
            "руками выталкивать себя вверх",
            "не блокировать локтевой сустав",
            "таз над плечами",
            "расширять грудь и спину",
            "выравнивать поясницу, не прогибаться",
            "живот и нижние ребра вовнутрь",
            "подкручивать таз",
            "внутренние поверхности бедер направлять вверх",
            "квадрицепсы активны",
            "вытягивать ноги",
            "стопы тянуть вверх",
            "тянуть носок"
        ]
    },
    {
        id: 11,
        img: yoga11,
        asanasName: "Peacock Feather Pose",
        title:"Pincha_Mayurasana",
        asanasNameRus: "Поза Пернатого Павлина",
        asanasNameDe: "die Pfauenfederstellung",
        asanasNameBe: "Пастава Птушынага Паўліна",
        asanasNameSanskrit:"Pincha Mayurasana",
        level: "advanced",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Пинча Маюрасана - перевернутая поза продвинутого уровня, которая требует не только правильной отстройки, силы, баланса, но и гибкости плечевого и верхнего отдела спины. Ее можно практиковать, когда практикующий способен удержать весь вес тела на предплечьях.",
        instructions: [
            "предплечья к полу, внешнее вращение",
            "предплечья параллельны, средний палец направлен вперед",
            "локти под плечами",
            "плечи внешнее вращение",
            "расширять грудь и спину",
            "не зажимать шею",
            "таз над плечами",
            "выравнивать поясницу, не прогибаться",
            "живот и нижние ребра вовнутрь",
            "подкручивать таз",
            "внутренние поверхности бедер направлять вверх",
            "квадрицепсы активны",
            "вытягивать ноги",
            "стопы тянуть вверх",
            "тянуть носок",
            "колени четко вперед",
            "живот и нижние ребра вовнутрь",
        ]
    },
    {
        id: 12,
        img: yoga12,
        asanasName: "Supported Headstand Pose",
        title:"Salamba_Sirsasana",
        asanasNameRus: "Стойка на Голове с Поддержкой",
        asanasNameDe: "der Handstand mit Unterstützung",
        asanasNameBe: "Стойка на Галaве з Падтрымкай",
        asanasNameSanskrit:"Salamba Sirsasana",
        level: "advanced",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Саламба Ширшасана это как перевернутая тадасана. Если запомнить и практиковать правильную отстройку, то выполнение будет намного проще и безопаснее. Поза рекомендуется для продвинутого уровня. Практикующий должен иметь сильное тело и руки, чтобы быть способным удержать весь вес тела на руках, не перенося его на шею или голову.",
        instructions: [
            "не переносить вес тела на голову",
            "плечи от ушей",
            "вытягивать запястья",
            "сильные активные руки",
            "выталкивать себя вверх",
            "голова: опора на плоскую часть между лбом и макушкой",
            "переплести пальцы - ладони обнимают голову",
            "уводить вес тела в руки",
            "живот и нижние ребра вовнутрь",
            "выравнивать поясницу, не прогибаться",
            "стопами тянуться вверх",
            "таз над плечами",
        ]
    },
    {
        id: 13,
        img: yoga13,
        asanasName: "Downward Facing Dog Pose",
        title:"Adho_Mukha_Svanasana",
        asanasNameRus: "Поза Собаки Мордой Вниз",
        asanasNameDe: "der Herabschauender Hund",
        asanasNameBe: "Пастава Сабакі Мордай Уніз",
        asanasNameSanskrit:"Adho Mukha Svanasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: a4,
        description: "Основная асана для проработки всего тела - от пальцев рук до пальцев ног. Часто используется, как отдых между асанами. Но при правильной отстройке и концентрации энергозатратна и требует постоянной коррекции и усилий.",
        instructions: [
            "выталкивать тело вверх",
            "вытягивать и расслаблять шею",
            "не зажимать лопатки",
            "вытягивать позвоночник",
            "предплечья - вращение вовнутрь",
            "внешнее вращение плеч",
            "живот вовнутрь",
            "нижние ребра вовнутрь",
            "копчик назад",
            "вытягивать икры",
            "вытягивать заднюю поверхность бедра",
            "пятки к полу",
            "переводить вес в ноги, убирая давление с рук",
            "спина прямая",
            "не скруглять поясницу",
            "седалищные косточки вверх",
        ]
    },
    {
        id: 14,
        img: yoga56,
        asanasName: "Upward Facing Dog Pose",
        title:"Urdhva_Mukha_Svanasana",
        asanasNameRus: "Поза Собаки Мордой Вверх",
        asanasNameDe: "der Aufschauender Hund",
        asanasNameBe: "Пастава Сабакі Мордай Уверх",
        asanasNameSanskrit:"Urdhva Mukha Svanasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: a4,
        description: "Урдхва Мукха Шванасана это базовый и часто используемый прогиб. Важно правильно отстраивать позу, что гарантирует безопасность практики и готовит тело к более продвинутым прогибам.",
        instructions: [
            "плечи вниз назад",
            "внешнее вращение плеч",
            "грудина вверх",
            "расширять грудную клетку",
            "выталкивать тело вверх",
            "поясница прямая",
            "предплечья внутренне вращение",
            "запястья под плечами",
            "лопатки вместе вниз",
            "локти назад, сохраняя микро сгибание",
            "бедро на весу", 
            "квадрицепс активный",
            "пятки вверх, не заваливать в сторону",
            "коленные чашечки к тазу",
            "лобковая кость к пупку",
            "запястья под плечами"
        ]


    },
    {
        id: 15,
        img: yoga15,
        asanasName: "Standing Forward Fold Pose",
        title:"Uttanasana",
        asanasNameRus: "Наклон Вперед Стоя",
        asanasNameDe: "die Stehende Vorwärtsbeuge",
        asanasNameBe: "Нахіл Наперад Стоячы",
        asanasNameSanskrit:"Uttanasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Поза Наклон Вперед Стоя - одна из базовых наклонов вперед. Вытягивает заднюю поверхность бедра и позвоночник. Рекомендуется для снятия стрессовых эмоций и ментального расслабления.",
        instructions: [
            "макушка в пол",
            "расслабить шею",
            "плечи от ушей",
            "разводить лопатки",
            "локти направлять назад",
            "вытягивать позвоночник",
            "вытягивать поясницу",
            "живот подтянут",
            "вращать бедра вовнутрь", 
            "седалищные косточки вверх",
            "колени вперед",
            "коленные чашечки вверх",
            "таз над пятками",
            "основной вес на переднем отделе стопы"
        ]
    },
    {
        id: 16,
        img: yoga16,
        asanasName: "Upward Forward Fold Pose",
        title:"Ardha_Uttanasana",
        asanasNameRus: "Сгиб Вперед Наполовину",
        asanasNameDe: "die halbe Vorbeuge",
        asanasNameBe: "Згін Наперад Напалову",
        asanasNameSanskrit:"Ardha Uttanasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "В Ардха Уттанасана приоритет отдаем вытяжению спины, а не задней поверхности бедер. Поэтому начинающим рекомендуется согнуть колени или установить ладони на блоки.",
        instructions: [
            "плечи от ушей",
            "вытягивать позвоночник",
            "вытягивать поясницу",
            "живот подтянут",
            "седалищные косточки вверх", 
            "квадрицепс активен",
            "коленные чашечки вверх",
            "таз над пятками",
        ]
    },
    {
        id: 17,
        img: yoga17,
        asanasName: "Both Big Toe Pose",
        title:"Urdhva_Padangusthasana",
        asanasNameRus: "Поза Балансируюшей Палки",
        asanasNameDe: "die Pose beider Großzehen",
        asanasNameBe: "Пастава Балансуючай Палкі",
        asanasNameSanskrit:"Urdhva Padangusthasana",
        level: "middle",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Убхая Падангуштхасана. В этой асане важно не заваливаться назад и не расслаблять ноги, удерживая их вес руками. Можно держать ноги на ширине плеч, можно вместе. Для начинающих ноги можно слегка согнуть.",
        instructions: [
            "вытяжение головы вверх",
            "живот вовнутрь",
            "плечи вниз",
            "ноги на ширине плеч или чуть шире",
            "держать ноги мышцами, не висеть за счет захвата руками",
            "вытягивать позвоночник",
            "поясница ровная",
            "балансировать между копчиком и седалищными косточками",
            "захват большим указательным и средним пальцем за большой палец ноги",
            "вытягивать руки",
        ]

    },
    {
        id: 18,
        img: yoga18,
        asanasName: "Hero Pose",
        title:"Virasana",
        asanasNameRus: "Поза Героя",
        asanasNameDe: "die Heldenpose",
        asanasNameBe: "Пастава Героя",
        asanasNameSanskrit:"Virasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Вирасана имеет терапевтический эффект. Очень хорошо снимает усталость ног для тех, кто основное время проводит на ногах. Улучшает гибкость суставов. кажется достаточно легкой в исполнении, однако многие начинающие встречаются с неожиданными проблемами в правильной отстройке позы.",
        instructions: [
            "макушка вверх",
            "расслабить плечи, отводя их немного назад",
            "подбородок параллельно полу",
            "лопатки слегка направить друг к другу",
            "вытягивать позвоночник",
            "вытягивать поясницу",
            "живот вовнутрь",
            "вес тела на седалищных косточках", 
            "колени вместе",
            "пальцы ног назад",
            "стопы вверх",
            "икры вращать наружу",
            "нижние ребра вовнутрь"
        ]
    },
    {
        id: 19,
        img: yoga19,
        asanasName: "Garland Pose",
        title:"Malasana",
        asanasNameRus: "Поза Гирлянды",
        asanasNameDe: "die Girlandenpose",
        asanasNameBe: "Пастава Гірлянды",
        asanasNameSanskrit:"Malasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Маласана имеет терапевтический эффект для улучшения пищеварения, профилактики запоров. Помогает раскрытию тазобедренных суставов и гибкости голеностопа.",
        instructions: [
            "макушка вверх",
            "плечи вниз",
            "шея длинная",
            "ладони вместе",
            "вытягивать позвоночник",
            "поясница прямая",
            "живот вовнутрь",
            "локтями расталкивать колени в стороны и назад", 
            "стопы и колени в одном направлении",
            "раскрывать грудь",
            "грудина вверх",
        ]
    },
    {
        id: 20,
        img: yoga20,
        asanasName: "Corpse Pose",
        title:"Savasana",
        asanasNameRus: "Поза Глубокого Расслабления",
        asanasNameDe: "die Totenstellung",
        asanasNameBe: "Пастава Глыбокага Расслаблення",
        asanasNameSanskrit:"Savasana",
        level: "beginner",
        type: "seated asana",
        duration: 600,
        additionalImg: null,
        description: "Шавасана - поза осознанного расслабления. Самая важная и самая сложная для большинства практикующих. Учит полному расслаблению ума и тела. Обычно выполняется в конце практики. Может длиться от 5 до 30 минут в зависимости от нужд практикующего и интенсивности асан.",
        instructions: [
            "подбородок слегка к груди",
            "плечи от ушей",
            "удлинить шею",
            "нейтральное положение спины",
            "живот мягкий",
            "ладони в потолок",
            "позволить стопам развалиться в стороны",
            "не засыпать",
        ]
    },
    {
        id: 21,
        img: yoga21,
        asanasName: "Seated Forward Bend Pose",
        title:"Pascimottanasana",
        asanasNameRus: "Наклон Вперед Сидя",
        asanasNameDe: "die Vorwärtsbeuge im Sitzen",
        asanasNameBe: "Нахіл Наперад Седзячы",
        asanasNameSanskrit:"Pascimottanasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Пасчимоттанасана одна из базовых асан наклона, происходит интенсивное вытяжение спины и задней поверхности ног. Для безопасной практики требует внимания к отстройке.",
        instructions: [
            "плечи от ушей параллельно полу",
            "грудина вперед",
            "седалищные косточки направлять назад",
            "внешнее вращение плеч",
            "вытягивать позвоночник",
            "вытягивать поясницу",
            "живот вовнутрь",
            "активный квадрицепс",
            "пальцы ног вверх"
        ]
    },
    {
        id: 22,
        img: yoga22,
        asanasName: "Chair Pose Arms Forward",
        title:"Utkatasana_variation",
        asanasNameRus: "Поза Стула с Руками Вперед",
        asanasNameDe: "die Stuhlhaltung Arme nach vorne",
        asanasNameBe: "Пастава Стула з Рукамі Наперад",
        asanasNameSanskrit:"Utkatasana variation",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Уткатасана с руками вытянутыми вперед может практиковаться теми, кто теряет равновесие при руках вытянутых вверх. Этот вариант хорош тем, у кого напряжена шея или сбивается дыхание при поднятии рук",
        instructions: [
            "плечи вниз",
            "локти вытянуты",
            "внешнее вращение плеч - направление трицепса вперед",
            "нижние ребра вовнутрь",
            "поясница плоская, не выгибать",
            "живот вовнутрь",
            "таз подкручен",
            "внутренние поверхности бедер направлять друг к другу",
            "лобковая кость к пупку",
            "копчик направлен в пол",
            "колени четко вперед и не выходят за линию стоп",
            "стопами отталкиваемся от пола",
            "основной вес на пятках",
        ]
    },
    {
        id: 23,
        img: yoga23,
        asanasName: "Fish Pose",
        title:"Matsyasana",
        asanasNameRus: "Поза Рыбы",
        asanasNameDe: "der Fisch",
        asanasNameBe: "Пастава Рыбы",
        asanasNameSanskrit:"Matsyasana",
        level: "middle",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Матсьясана способствует раскрытию грудного отдела, часто используется для компенсации после халасаны, карнапидасаны или сарваргасаны.",
        instructions: [
            "раскрывать грудную клетку",
            "живот вовнутрь",
            "колени выпрямлять",
            "стопы вместе",
            "макушка в пол",
            "вытягивать позвоночник",
            "вес направить в локти, выталкивая грудную клетку",
            "не зажимать поясницу",
            "плечи от ушей",
            "вытягивать ноги",
        ]
    },
    {
        id: 24,
        img: yoga24,
        asanasName: "Happy Baby Pose",
        title:"Ananda_Balasana",
        asanasNameRus: "Поза Счастливого Ребенка",
        asanasNameDe: "das glückliches Baby",
        asanasNameBe: "Пастава шчаслівага дзіцяці",
        asanasNameSanskrit:"Ananda Balasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Ананда Баласана часто используется для отдыха и восстановления после активной практики. Раскрывает тазобедренные суставы, расслабляет спину.",
        instructions: [
            "пятки в потолок",
            "взгляд вверх",
            "вытянуть шею и расслабить",
            "вытягивать позвоночник",
            "поясница в пол",
            "крестец к полу",
            "колени к полу",
        ]
    },
    {
        id: 25,
        img: yoga25,
        asanasName: "Scale Pose",
        title:"Tolasana",
        asanasNameRus: "Поза Весы",
        asanasNameDe: "die Waage",
        asanasNameBe: "Пастава Шалі",
        asanasNameSanskrit:"Tolasana",
        level: "advanced",
        type: "seated asana",
        duration: 30,
        additionalImg: a4,
        description: "Толасана. Этот баланс способствует укреплению всего мышечного корсета. Иногда поза не дается из-за анатомических особенностей - длины рук. В этом случае нужно использовать блоки как опору под ладони",
        instructions: [
            "не зажимать шею",
            "плечи вниз",
            "не висеть на запястьях",
            "поднимать вес, удерживая усилием пресса и бандх",
            "раскрывать грудь, не зажимать",
            "таз направлять назад",
            "подняться на выдохе, не задерживать дыхание",
            "выталкивать себя вверх",
            "пальцы рук активные, средний палец направлен вперед",
        ]
    },
    {
        id: 26,
        img: yoga26,
        asanasName: "Bow Pose",
        title:"Dhanurasana",
        asanasNameRus: "Поза Лука",
        asanasNameDe: "die Bogenhaltung",
        asanasNameBe: "Пастава Лука",
        asanasNameSanskrit:"Dhanurasana",
        level: "middle",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Дханурасана - прогиб, который способствует гибкости позвоночника, раскрытию грудного отдела. Имеет вариации в захвате ног и положении рук, что помогает усложнить или упростить асану. Важно соблюдать отстройку в любой вариации.",
        instructions: [
            "лопатками выталкивать грудную клетку вверх",
            "вытягивать позвоночник",
            "не зажимать поясницу",
            "копчик направлять назад",
            "бедра вращать вовнутрь",
            "поднимать колени",
            "стараться соединить ноги",
            "пятки удалять от ягодиц",
        ]
    },
    {
        id: 27,
        img: yoga27,
        asanasName: "Heart Melting Pose",
        title:"Anahatasana",
        asanasNameRus: "Поза Сердца",
        asanasNameDe: "die Haltung des Herzens",
        asanasNameBe: "Пастава Сэрца",
        asanasNameSanskrit:"Anahatasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Анахатасана - одна из самых доступных поз для вытяжения позвоночника и открытия грудного отдела без риска травмы. Подготавливает к освоению Пинча Маюрасаны.",
        instructions: [
            "седалищные косточки вверх",
            "вытягивать позвоночник, увеличивая расстояние между тазом и грудной клеткой",
            "раздвигать лопатки в стороны",
            "грудина в пол",
            "живот вовнутрь",
            "колени на ширине таза",
            "подъемы в пол",
            "таз над коленями",
            "плечи вращать наружу",
            "вытягивать локти",
            "предплечья вращать вовнутрь",
            "большой палец, указательный палец и мизинец в пол"
        ]
    },
    {
        id: 28,
        img: yoga28,
        asanasName: "Cow Pose",
        title:"Bitilasana",
        asanasNameRus: "Поза Коровы",
        asanasNameDe: "die Kuhpose",
        asanasNameBe: "Пастава Каровы",
        asanasNameSanskrit:"Bitilasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Битиласана удлиняет переднюю поверхность корпуса и шеи. При неприятных ощущениях в области шеи удерживайте голову на одной линии с корпусом. Часто используется в связке с Позой Кошки.",
        instructions: [
            "плечи от ушей",
            "пальцы ладоней веером",
            "плечи над запястьями",
            "макушка вперед",
            "живот вовнутрь",
            "колени на ширине таза",
            "таз над коленями",
            "вдох - прогиб",
            "выдох - возвращаемся назад"
        ]
    },
    {
        id: 29,
        img: yoga29,
        asanasName: "Cat Pose",
        title:"Marjaryasana",
        asanasNameRus: "Поза Кошки",
        asanasNameDe: "die Katzenpose",
        asanasNameBe: "Пастава Коткі",
        asanasNameSanskrit:"Marjaryasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Марджарьясана - поза счастливого позвоночника. Часто эта поза практикуется с позой коровы Битиласана. Растяжка в позе кошки улучшает кровообращение, восстанавливает шею, спину и плечи.",
        instructions: [
            "плечи от ушей",
            "пальцы ладоней веером",
            "плечи над запястьями",
            "макушка вперед",
            "живот вовнутрь",
            "колени на ширине таза",
            "таз над коленями",
        ]

    },
    {
        id: 30,
        img: yoga30,
        asanasName: "Crescent Low Lunge Pose",
        title:"Anjaneyasana",
        asanasNameRus: "Поза низкого выпада",
        asanasNameDe: "Tiefer Ausfallschritt",
        asanasNameBe: "Пастава нізкага выпаду",
        asanasNameSanskrit:"Anjaneyasana",
        level: "middle",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Аджанеясана улучшает гибкость позвоночника и плечевых суставов, подготавливает тело к освоению Хануманасы. При неприятных ощущениях в колене задней ноги имеет смысл подкладывать сложенное полотенце.",
        instructions: [
            "намерение соеденить запястья",
            "вытягивать локти",
            "взгляд на большие пальцы",
            "плечи внешнее вращение, трицепс направлять вперед",
            "не бросать голову, укладывать на трапециевидные мышцы",
            "грудина вверх, раскрывать грудную клетку",
            "нижние ребра вовнутрь",
            "вытягивать позвоночник, не зажимать лопатки, плечи вниз",
            "колено согнутой передней ноги над пяткой",
            "таз закрыт",
            "не нагружать колено задней ноги",
            "вытяжение корпуса вверх"
        ]
    },
    {
        id: 31,
        img: yoga31,
        asanasName: "Plank Pose",
        title:"Kumbhakasana",
        asanasNameRus: "Планка На Вытянутых Руках",
        asanasNameDe: "die Plankenhaltung",
        asanasNameBe: "Планка на выцягнутых руках",
        asanasNameSanskrit:"Kumbhakasana",
        level: "middle",
        type: "prone asana",
        duration: 30,
        additionalImg: a4,
        description: "Основная силовая асана в практике, которая готовит тело для более продвинутых силовых асан, такие как балансы на руках и перевернутые позы. Правильная отстройка помогает понять принципы отстроек остальных силовых асан.",
        instructions: [
            "разводить лопатки, толкая место между лопатками вверх",
            "не висеть всем телом на запястьях, удерживать вес мышцами всего тела",
            "пальцы рук активные, раскрыты веером",
            "при гиперподвижных локтевых суставах сохранять легкое сгибание",
            "запястья под плечами, но если за Планкой следует Чатуранга, то руки слегка разведены",
            "внешнее вращение плеч",
            "внутренее вращение предплечий",
            "плечи от ушей",
            "удлинять шею",
            "не прогибаться в пояснице",
            "таз подкручен",
            "корпус держать ровно",
            "толкать тело вверх", 
            "живот вовнутрь",
            "квадрицепс активный",
            "подтягивать коленные чашечки вверх",
            "лобковая кость к пупку",
        ]
    },
    {
        id: 32,
        img: yoga32,
        asanasName: "Locust Pose variation",
        title:"Salabhasana_variation",
        asanasNameRus: "Поза Саранчи вариация",
        asanasNameDe: "die Heuschrecke",
        asanasNameBe: "Пастава Саранчы",
        asanasNameSanskrit:"Salabhasana",
        level: "middle",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Шалабхасана - силовая асана для укрепления задней части тела. Важно делать асану за счет мышц, не давая импульса для поднятия ног и корпуса. Не перенапрягать поясницу, не зажимать плечи. Вход и выход в асану плавный.",
        instructions: [
            "плечи от ушей",
            "грудь вверх",
            "толкать лопатки в сторону, расширяя спину",
            "не зажимать поясницу",
            "копчик направлять к пяткам",
            "подкручивать таз",
            "бедра вовнутрь",
            "квадрицепс активный",
            "стопы вместе пятки вверх",
            "вытягивать ноги, стопы активные",
            "живот вовнутрь",
            "на вдохе руки, корпус, ноги вверх"
        ]
    },

    {
        id: 33,
        img: yoga33,
        asanasName: "Cobra Pose",
        title:"Bhujangasana",
        asanasNameRus: "Поза Кобры",
        asanasNameDe: "die Kobrapose",
        asanasNameBe: "Пастава Кобры",
        asanasNameSanskrit:"Bhujangasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Бхуджангасана нацелена на нижнюю часть спины, грудную клетку, руки, плечи и живот. Это одна из основных поз для прогиба назад. Главное в позе - уменьшать давление на поясницу. Эта асана помогает повысить энергию в теле.",
        instructions: [
            "подбородок от груди",
            "грудь вверх",
            "плечи от ушей",
            "таз подкручен",
            "ягодицы активны",
            "внешнее вращение плеч",
            "глубокий вдох при прогибе",
            "руками от пола не отталкиваться",
            "пол на себя",
            "пальцы ног на полу",
            "живот вовнутрь",
        ]
    },
    {
        id: 34,
        img: yoga34,
        asanasName: "Low Plank",
        title:"Chaturanga_Dandasana",
        asanasNameRus: "Поза Посоха",
        asanasNameDe: "die Vier-Glied-Stab Pose",
        asanasNameBe: "Пастава Кій",
        asanasNameSanskrit:"Chaturanga Dandasana",
        level: "middle",
        type: "prone asana",
        duration: 30,
        additionalImg: a4,
        description: "Чатуранга - это продвинутая версия Планки На Вытянутых Руках. Базовая силовая асана для укрепления всего тела, подготовка к продвинутым силовым и перевернутым асанам. Технику этой асаны рекомендуется осваивать постепенно, распределяя нагрузку. Приоритет отдается отстройке корпуса и рук. Начинающие могут опускать колени на пол.",
        instructions: [
            "не сжимать лопатки",
            "запястья под плечами",
            "плечи не опускать ниже линии локтей",
            "плечи от ушей",
            "удлинять шею",
            "не прогибаться в пояснице",
            "таз подкручен",
            "корпус держать ровно",
            "живот вовнутрь",
            "квадрицепс активный",
            "подтягивать коленные чашечки вверх",
            "лобковая кость к пупку",
            "пятки толкать назад",
            "взгляд в пол",
            "точки опоры: большой, указательный, маленький палец, мягкая часть ладони, средний палец направлен вперед"        
        ]
    },
    {
        id: 35,
        img: yoga35,
        asanasName: "Child Pose",
        title:"Balasana",
        asanasNameRus: "Поза Ребенка",
        asanasNameDe: "die Kinderhaltung",
        asanasNameBe: "Пастава Дзіцяці",
        asanasNameSanskrit:"Balasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Баласана используется для отдыха, восстановления дыхания. Основная задача - успокоить ум и расслабить тело.",
        instructions: [
            "вытягивать и расслаблять позвоночник",
            "раздвигать лопатки в стороны и расслабить",
            "лоб в пол",
            "ягодицы на пятках",
            "ладони в потолок рядом с пятками",
            "полностью расслабить плечи",
        ]
    },
    {
        id: 36,
        img: yoga36,
        asanasName: "Locust Pose",
        title:"Salabhasana",
        asanasNameRus: "Поза Саранчи",
        asanasNameDe: "die Heuschrecke",
        asanasNameBe: "Пастава Саранчы",
        asanasNameSanskrit:"Salabhasana",
        level: "middle",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Шалабхасана - силовая асана для укрепления задней части тела. Важно делать асану за счет мышц, не давая импульса для поднятия ног и корпуса. Не перенапрягать поясницу, не зажимать плечи. Вход и выход в асану плавный.",
        instructions: [
            "плечи от ушей",
            "грудь вверх",
            "толкать лопатки в сторону, расширяя спину",
            "не зажимать поясницу",
            "копчик направлять к пяткам",
            "подкручивать таз",
            "бедра вовнутрь",
            "квадрицепс активный",
            "стопы вместе пятки вверх",
            "вытягивать ноги, стопы активные",
            "живот вовнутрь"
        ]
    },
    {
        id: 37,
        img: yoga37,
        asanasName: "Halbboot Pose",
        title:"Ardha_Navasana",
        asanasNameRus: "Поза Полулодка",
        asanasNameDe: "das Halbe Boot",
        asanasNameBe: "Пастава Паўлодка",
        asanasNameSanskrit:"Ardha Navasana",
        level: "middle",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Ардха Навасана - силовая асана, укрепляюшая мышечный корсет. Начинающим рекомендуется освоение позы лежа на спине, с вытянутыми руками вдоль тела и поднятием ног на 45 градусов.",
        instructions: [
            "шею вытягивать",
            "плечи от ушей",
            "лопатки отрывать от пола",
            "поясницу в пол",
            "таз подкручен",
            "квадрицепсы активны",
            "стопы активны и вместе",
            "грудь вверх",
            "бедра вращать вовнутрь",
            "живот вовнутрь",
        ]
    },
    {
        id: 38,
        img: yoga38,
        asanasName: "Upward Plank Pose",
        title:"Purvottanasana",
        asanasNameRus: "Поза Планка Вверх",
        asanasNameDe: "die Schiefe Ebene",
        asanasNameBe: "Пастава Планка Уверх",
        asanasNameSanskrit:"Purvottanasana",
        level: "middle",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Пурвоттанасана укрепляет мышцы всего тела, особенно силу рук. В ней возможны разные вариации: или запрокинуть голову назад или устремить взгляд вверх, вытягивая заднюю поверхность шеи; пальцы рук могут быть направлены в сторону головы или в сторону пяток.",
        instructions: [
            "плечи от ушей параллельно полу",
            "грудина вверх",
            "не блокировать локтевой сустав, сохранять микросгибание",
            "запястья под плечами",
            "вытягивать позвоночник",
            "таз вверх",
            "живот вовнутрь",
            "активный квадрицепс",
            "лобковая кость на себя",
            "вытягивать ноги",
            "стопы прижать к полу"
        ]

    },
    {
        id: 39,
        img: yoga39,
        asanasName: "Crane Pose",
        title:"Bakasana",
        asanasNameRus: "Поза Журавля",
        asanasNameDe: "die Kranichpose",
        asanasNameBe: "Пастава Жураўля",
        asanasNameSanskrit:"Bakasana",
        level: "advanced",
        type: "standing asana",
        duration: 30,
        additionalImg: a4,
        description: "Бакасана одна из первых поз на баланс на руках. Особенно хороша для начинающих. Укрепляет руки и все тело, готовя его к более продвинутым асанам, таким как поласана, топасана и др.",
        instructions: [
            "поднимать таз вверх силой пресса",
            "пятки к ягодицам",
            "выталкивать тело вверх, не висеть на руках",
            "вытягивать позвоночник",
            "подтягивать живот вовнутрь",
            "грудину направлять вперед",
            "силой пресса и бандх стараться оторвать колени от рук, поднимая тело вверх",
            "колени в подмышках",
            "стараться вытянуть локти",
            "активные пальцы расправлены веером",
            "плечи от ушей"
        ]
    },
    {
        id: 40,
        img: yoga40,
        asanasName: "Sage Twist Pose III",
        title:"Marichyasana_III",
        asanasNameRus: "Поза Мудреца III",
        asanasNameDe: "die Weise Pose III",
        asanasNameBe: "Пастава Мудраца III",
        asanasNameSanskrit:"Marichyasana III",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Маричасана - скрутка, которая тонизирует внутренние органы. Способствует развитию гибкости, имеет много вариаций, которые позволяют упрощать или усложнять асану. На картинке самая доступная вариация.",
        instructions: [
            "макушка вверх",
            "плечи от ушей",
            "движение идет за грудной клеткой, а не за шеей",
            "вытягивать позвоночник",
            "вытягивать шею",
            "не заваливаться назад",
            "не переносить вес на руку",
            "локоть помогает скрутке",
            "не зажимать грудную клетку",
            "равномерно распределить вес на обе седалищные косточки",
            "вытяжение корпуса от седалищных косточек",
        ]
    },
    {
        id: 41,
        img: yoga41,
        asanasName: "Plow Pose",
        title:"Halasana",
        asanasNameRus: "Поза Плуга",
        asanasNameDe: "die Pflugpose",
        asanasNameBe: "Пастава Плуга",
        asanasNameSanskrit:"Halasana",
        level: "middle",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Халасана перевернутая асана. Способствует пищеварению, улучшению кровообращения, тонизирует внутренние органы, помошает урегулировать гормональный фон. Важно: при выполнении плечи стремятся друг к другу, тело опирается на плечи и таким образом оно слегка поднимается и снижается давление на шею.",
        instructions: [
            "таз над плечами",
            "седалищные косточки вверх",
            "колени выпрямлять",
            "стопы вместе",
            "пятки назад",
            "вытягивать позвоночник",
            "поясница и спина ровные",
            "вытягивать запястья и направлять в пол",
            "вытягивать руки",
            "вытягивать шею",
            "вытягивать ноги",
        ]
    },
    {
        id: 42,
        img: yoga42,
        asanasName: "Side Angle Pose",
        title:"Parsvakonasana",
        asanasNameRus: "Вытянутый Боковой Угол",
        asanasNameDe: "die Seitwinkel-Pose",
        asanasNameBe: "Выцягнуты Бакавы Кут",
        asanasNameSanskrit:"Parsvakonasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: a3,
        description: "Паршваконасана укрепляет все тело, раскрывает тазобедренные суставы, способствует вытяжению боковых мышц копуса. Начинающим рекомендуется устанавливать руку на блок.",
        instructions: [
            "плечи от ушей",
            "копус активный, вращать вверх, не висеть на руке",
            "колено к плечу",
            "колено над пяткой не заваливать вовнутрь",
            "удлинять шею",
            "вес распределять на обе ноги",
            "плечо отводить от уха",
            "ладонь в пол",
            "вытягивать руку",
            "взгляд на ладонь",
            "бедро параллельно полу, прямой угол к голени",
            "стопа задней ноги на линии пятки передней ноги, образуя прямой угол",
        ]
    },
    {
        id: 43,
        img: yoga65,
        asanasName: "Revolved Side Angle Pose",
        title:"Paryvritta_Parsvakonasana",
        asanasNameRus: "Перевернутый Боковой Угол",
        asanasNameDe: "die Gedrehte Seitwinkel-Pose",
        asanasNameBe: "Перавернуты Бакавы Кут",
        asanasNameSanskrit:"Paryvritta Parsvakonasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: a3,
        description: "Паривритта Паршвоконасана укрепляет все тело, тонизируя внутренние органы, обеспечивая им мягкий массаж. Существует множество вариантов скрутки и отстройки руки. Начинающим рекомндуется оставить руку с внутренней стороны стопы. Продолжающие могут заводить руку под бедро, выполняя захват за спиной.",
        instructions: [
            "ладонь в пол",
            "плечи от ушей",
            "мягкий взгляд на ладонь",
            "бедро параллельно полу, прямой угол к голени",
            "вытягивать руку",
            "колено над пяткой не заваливать вовнутрь",
            "бедро параллельно полу, прямой угол к голени",
            "копус активный, вращать вверх, не висеть на руке",
            "грудной отдел вверх",
            "бедро вытянутой ноги толкать вверх",
            "пятку вытянутой ноги толкать вверх",
            "выравнивать таз",
            "подмышка на бедре",
            "корпус вращать вверх",
            "квадрицепс активен"
        ]
    },
    {
        id: 44,
        img: yoga44,
        asanasName: "Warrior Pose II",
        title:"Virabhadrasana_II",
        asanasNameRus: "Поза Воина II",
        asanasNameDe: "der Krieger II",
        asanasNameBe: "Пастава Воіна II",
        asanasNameSanskrit:"Virabhadrasana II",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: a3,
        description: "Вирабхадрасана II схожа с Вирабхадрасана I, но основное внимание направлено на отстройку таза и постановку передней ноги. Асана позволяет работать над раскрытием тазобедренных суставов .",
        instructions: [
            "вытягивать руки в стороны",
            "плечи вниз",
            "разводить лопатки",
            "плечи над тазом, корпус ровно",
            "руки параллельно полу",
            "плечи вращать наружу, предплечья вращать вовнутрь",
            "бедра вращать наружу",
            "подвздошные косточки на одной линии направлены вперед",
            "бедро передней ноги параллельно полу, голень 90 градусов к полу",
            "колено над пяткой не заваливать вовнутрь",
            "лобковая кость на себя",
            "направлять вес в обе ноги",
        ]

    },
    {
        id: 45,
        img: yoga45,
        asanasName: "Warrior Pose I",
        title:"Virabhadrasana_I",
        asanasNameRus: "Поза Воина I",
        asanasNameDe: "der Krieger I",
        asanasNameBe: "Пастава Воіна I",
        asanasNameSanskrit:"Virabhadrasana I",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: a5,
        description: "Вирабхадрасана I одна из самых энергозатратных поз, требует концентрацию в отстройке, прорабатывает ноги, спину, мышцы корпуса, руки. Улучшает гибкость и чувство баланса.",
        instructions: [
            "вытягиваться вверх",
            "намерение соединить запястья",
            "взгляд на большие пальцы рук",
            "вытягивать локти",
            "грудина вверх",
            "плечи над тазом, корпус ровно",
            "не зажимать плечи и лопатки",
            "направлять подвздошную кость задней ноги вперед",
            "бедро задней вытянутой ноги заворачивать вовнутрь",
            "направлять подвздошную кость передней ноги назад",
            "бедро передней ноги параллельно полу, голень 90 градусов к полу",
            "лобковая кость вверх, копчик в пол",
            "направлять вес в обе ноги",
        ]
    },
    {
        id: 46,
        img: yoga46,
        asanasName: "Wheel Pose",
        title:"Urdhya_Dhanurasana",
        asanasNameRus: "Поза Колеса",
        asanasNameDe: "das Rad",
        asanasNameBe: "Пастава Кола",
        asanasNameSanskrit:"Urdhya Dhanurasana",
        level: "middle",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Один из самых интенсивных базовых прогибов. Эта асана укрепляет все тело, раскрывает грудной отдел и способствует развитию гибкости позвоночника. Со временем практикующие приобретают уверенность на эмоциональном уровне.",
        instructions: [
            "таз подкручен",
            "копчик назад",
            "ракрывается грудь",
            "плечи от ушей",
            "локтями толкать грудь вверх",
            "локти направлять вперед",
            "не зажимать плечи и лопатки",
            "квадрицепсы активны",
            "колени и стопы вперед",
            "ягодицы активны",
            "живот вовнутрь",
            "макушка в пол",
            "переводить вес вперед мягко отталкиваясь ногами",
            "руками и стопами выталкивать себя вверх"
        ]
    },
    {
        id: 47,
        img: yoga47,
        asanasName: "Half Splits Pose",
        title:"Ardha_Hanumanasana",
        asanasNameRus: "Поза Прыжок Любви",
        asanasNameDe: "die Halbe Spagat Pose",
        asanasNameBe: "Пастава Скачок Любові",
        asanasNameSanskrit:"Ardha Hanumanasana",
        level: "middle",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Ардха Хануманасана помогает гибкости задней поверхности бедра, помогает отработать отстройку таза.",
        instructions: [
            "плечи от ушей",
            "раздвигать лопатки в разные стороны",
            "вытягивать позвоночник",
            "таз над коленом, не заваливать в сторону",
            "живот вовнутрь",
            "бедро согнутой ноги толкать вперед",
            "не зажимать плечи и лопатки",
            "квадрицепс передней ноги активен",
            "пальцы стопы передней ноги направлены вверх",
        ]
    },
    {
        id: 48,
        img: yoga48,
        asanasName: "Half Moon Pose",
        title:"Ardha_Chandrasana",
        asanasNameRus: "Поза Полумесяца",
        asanasNameDe: "die Halbmondpose",
        asanasNameBe: "Пастава Паўмесяца",
        asanasNameSanskrit:"Ardha Chandrasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Ардха Чандрасана укрепляет все тело, требует полной концентрации и нахождения в моменте, успокаивает ум и тем самым приводит к снятию/уменьшению стресса. Начинающим рекомендуется опорную руку устанавливать на блок.",
        instructions: [
            "мягкий взгляд вверх",
            "вытягивать шею",
            "плечи в одной плоскости одно над другим",
            "вытяжение обеих рук",
            "отталкиваемся от пола, вытяжение вверх",
            "выравниваем таз, подвздошные косточки одна над другой",
            "грудная клетка вращается вверх",
            "коленная чашечка вверх",
            "нижнюю ногу не вытягивать полностью, не блокировать сустав",
            "корпус автивный, не висеть на руке",
            "вытягивать бока",
        ]
    },
    {
        id: 49,
        img: yoga49,
        asanasName: "Revolved Triangle Pose",
        title:"Parivritta_Trikonasana",
        asanasNameRus: "Поза Перевернутый Треугольник",
        asanasNameDe: "die gestreckte Dreieckshaltung",
        asanasNameBe: "Пастава Перавернуты Трохвугольнік",
        asanasNameSanskrit:"Parivritta Trikonasana",
        level: "middle",
        type: "standing asana",
        duration: 30,
        additionalImg: a3,
        description: "Интенсивная скрутка. Укрепляет все тело, тонизирует внутренние органы, улучшает гибкость и концентрацию. Начинающим рекомендуется опорную руку устанавливать на блок.",
        instructions: [
            "мягкий взгляд вверх на большой палец вытянутой руки",
            "вытягивать позвоночник",
            "плечи от ушей",
            "вытяжение обеих рук",
            "вес на обе ноги",
            "ноги не друг за другом, а на ширине таза при виде спереди",
            "грудная клетка вращается вверх",
            "коленная чашечка вверх",
            "активировать квадрицепсы, не блокировать сустав",
            "бедро задней ноги направлять вперед",
            "бедро передней ноги направлять назад",
            "вытягивать бока",
        ]
    },
    {
        id: 50,
        img: yoga50,
        asanasName: "Extended Triangle Pose",
        title:"Utthita_Trikonasana",
        asanasNameRus: "Поза Треугольника Расширенная",
        asanasNameDe: "die erweiterte Dreieckshaltung",
        asanasNameBe: "Пастава Трохвугольніка Пашыраная",
        asanasNameSanskrit:"Utthita Trikonasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: a3,
        description: "Базовая асана на проработку мышц всего тела, требует концентрации, происходит раскрытие тазобедренных суставов. Начинающим рекомендуется опорную руку устанавливать на блок.",
        instructions: [
            "мягкий взгляд вверх на большой палец вытянутой руки",
            "вытягивать позвоночник",
            "плечи в одной плоскости одно над другим",
            "вытяжение обеих рук",
            "вес на обе ноги",
            "вращать бедро наружу, раскрывать таз",
            "грудная клетка вращается вверх",
            "коленная чашечка вверх",
            "активировать квадрицепсы, не блокировать сустав",
            "корпус автивный, не висеть на руке, не висеть на коленях",
            "вытягивать бока",
        ]
    },
    {
        id: 51,
        img: yoga51,
        asanasName: "Corpse Pose",
        title:"Savasana_variation",
        asanasNameRus: "Поза Глубокого Расслабления",
        asanasNameDe: "die Totenstellung",
        asanasNameBe: "Пастава Глыбокага Расслаблення",
        asanasNameSanskrit:"Savasana",
        level: "beginner",
        type: "seated asana",
        duration: 600,
        additionalImg: null,
        description: "Шавасана - поза осознанного расслабления. Самая важная и самая сложная для большинства практикующих. Учит полному расслаблению ума и тела. Обычно выполняется в конце практики. Может длиться от 5 до 30 минут в зависимости от нужд практикующего и интенсивности асан.",
        instructions: [
            "подбородок слегка к груди",
            "плечи от ушей",
            "удлинить шею",
            "нейтральное положение спины",
            "живот мягкий",
            "ладони в потолок",
            "позволить стопам развалиться в стороны",
            "не засыпать",
        ]
    },
    {
        id: 52,
        img: yoga52,
        asanasName: "Reclined Bound Angle Pose",
        title:"Supta_Baddha_Konasana",
        asanasNameRus: "Поза Связанного Угла",
        asanasNameDe: "der liegende Schmetterling",
        asanasNameBe: "Пастава Звязанага Кута",
        asanasNameSanskrit:"Supta Baddha Konasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Супта Баддха Конасана - расслабляющая асана, которая имеет расслабляющий эффект. Помогает снять напряжение, раскрывая тазобедренные суставы. Используется как отдых после активной связки. Рекомендуется при менструальных болях.",
        instructions: [
            "плечи от ушей", 
            "спина расслаблена, в нейтральном положении",
            "живот мягкий",
            "крестец в пол",
            "ладони вверх",
            "стопы вместе",
            "позволить коленям направится к полу"
        ]
    },
    {
        id: 53,
        img: yoga53,
        asanasName: "Wide-Legged Forward Bend Pose",
        title:"Prasarita_Padottanasana",
        asanasNameRus: "Наклон с Широкими Ногами",
        asanasNameDe: "die weitbeinige Vorwärtsbeuge",
        asanasNameBe: "Нахіл з Шырокімі Нагамі",
        asanasNameSanskrit:"Prasarita Padottanasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Прасарита Падоттанасана полезна для растяжки икр, бедер и тазобедренных суставов. Укрепляет стопы, лодыжки и ноги.",
        instructions: [
            "коленные чашечки вытягиваем",
            "удлинять спину, не сгибать",
            "голова стремится к полу",
            "стопы завернуты носками внутрь",
            "ноги не разъезжаются",
            "внутренне вращение бедер",
            "таз наружу"
        ]
    },
    {
        id: 54,
        img: yoga54,
        asanasName: "3-Legged Downward Facing Dog",
        title:"Tri_Pada_Adho_Mukha_Svanasana",
        asanasNameRus: "Поза Собаки Мордой Вниз",
        asanasNameDe: "3-beinige nach unten gerichtete Hundehaltung",
        asanasNameBe: "Пастава Сабакі Мордай Уніз",
        asanasNameSanskrit:"Tri Pada Adho Mukha Svanasana",
        level: "middle",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Трипада Адхо Мукха Шванасана - это вариация позы Собака Мордой Вниз. Здесь происходит более глубокое раскрытие бедра, что подготавливает к продвинутой практике. Укрепляется корпус, раскрываются поясничные мышца.",
        instructions: [
            "нога вытянута, согнута, стремится к бедру",
            "коленная чашечка вверх",
            "выталкивать тело вверх",
            "не зажимать лопатки",
            "вытягивать позвоночник",
            "внешнее вращение плеч",
            "живот вовнутрь",
            "нижние ребра вовнутрь",
            "копчик назад",
            "спина прямая",
            "не скруглять поясницу",
            "седалищные косточки вверх"
        ]
    },
    {
        id: 55,
        img: yoga55,
        asanasName: "Pigeon Pose",
        title:"Eka_Pada_Rajakapotasana",
        asanasNameRus: "Поза Голубя",
        asanasNameDe: "die Taubenpose",
        asanasNameBe: "Пастава Голубя",
        asanasNameSanskrit:"Eka Pada Rajakapotasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Эка Пада Раджакапотасана вытягивает мыщцы бедер и раскрывает таз. Развивает гибкость, которая необходима для выполнения продвинутых асан.",
        instructions: [
            "таз стабилен, не заваливается в сторону",
            "таз закрыт - выпрямленная нога не раскрыта наружу",
            "равномерное вытяжение боков",
            "плечи от ушей",
            "плечи на одной линии",
            "макушка вверх",
            "живот вовнутрь",
            "нижние ребра вовнутрь",
            "колено согнутой ноги вперед",
            "пятка вытянутой ноги в потолок",
            "бедро согнутой ноги на полу"
        ]
    },
    {
        id: 56,
        img: yoga57,
        asanasName: "Butterfly Pose",
        title:"Baddha_Konasana",
        asanasNameRus: "Поза Бабочки",
        asanasNameDe: "die Schmetterlingpose",
        asanasNameBe: "Пастава Матылі",
        asanasNameSanskrit:"Baddha Konasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Бадха Конасана способствует раскрытию тазобедренных суставов. Рекомендуется для облегчения менструальных болей.",
        instructions: [
            "плечи вниз",
            "вытягиваться вверх",
            "раскрывать грудь, направлять вверх",
            "удлинять позвоночник",
            "раскрывать стопы как книгу",
            "раскрывая колени, направлять их назад на линию бедер",
            "внешнее вращение бедер",
            "раскрытие в тазобедренных",
            "колени мягко направлять в пол",
            "пятки приближать к промежности"
        ]
    },
    {
        id: 57,
        img: yoga58,
        asanasName: "Half Wind Release Pose",
        title:"Ardha_Pavan_Muktasana",
        asanasNameRus: "Поза Полувысвобождения Ветра",
        asanasNameDe: "die Halbe windbefreiende Stellung",
        asanasNameBe: "Пастава Паўвызвалення Ветру",
        asanasNameSanskrit:"Ardha Pavan Muktasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Ардха Паванмуктасана - восстанавливающая поза. Хорошо подходит людям с большим животом. Поза помогает расслабить мышцв спины, способствует детоксикации.",
        instructions: [
            "позвоночник прижат к полу",
            "плечи от ушей",
            "нога прижата к животу",
            "живот вовнутрь",
            "взгляд вверх"
        ]
    },
    {
        id: 58,
        img: yoga59,
        asanasName: "Reclining Hero Pose",
        title:"Supta_Virasana",
        asanasNameRus: "Поза Лежащего Героя",
        asanasNameDe: "die liegende Heldenhaltung",
        asanasNameBe: "Пастава ляжачага Героя",
        asanasNameSanskrit:"Supta Virasana",
        level: "middle",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Супта Вирасана - поза для продвинутого уровня. Начинающим не рекомендуется выполнять эту позу до тех пор, пока не появится уверенность сидеть относительно легко на полу между своими ступнями.",
        instructions: [
            "бедра между пятками", 
            "таз на полу",
            "пальцы ног направлены назад",
            "при дискомфорте - подложить под спину валик или подушку",
            "голова на полу",
            "живот вовнутрь"
        ]
    },
    {
        id: 59,
        img: yoga14,
        asanasName: "Boat Pose",
        title:"Paripurna_Navasana",
        asanasNameRus: "Поза Лодки",
        asanasNameDe: "das Boot",
        asanasNameBe: "Пастава Лодкі",
        asanasNameSanskrit:"Paripurna Navasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Парипурна Навасана укрепляет полностью мышечный корсет, способствуя развитию баланса. Подготавливает мышцы тора к более продвинутым асанам. Имеет вариации для начинающих: сгибание ног так, чтобы голени были параллельно полу, руками опираясь на пол. Возможны 2 варианта: стопы на себя или стопы вверх.",
        instructions: [
            "грудина вверх",
            "плечи вниз",
            "сводить лопатки",
            "ладони вместе",
            "вытягивать позвоночник",
            "поясница прямая",
            "ноги вытягивать",
            "живот вовнутрь",
            "ноги направлять к туловищу",
            "активный квадрицепс",
            "не заваливаться на копчик", 
            "балансировать между седалищными и копчиком",
        ]

    },

    {
        id: 60,
        img: yoga60,
        asanasName: "Frog Pose II",
        title:"Bhekasana",
        asanasNameRus: "Поза Лягушки II",
        asanasNameDe: "die Froschpose II",
        asanasNameBe: "Пастава Жабы II",
        asanasNameSanskrit:"Bhekasana",
        level: "middle",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Бхекасана - продвинутая асана, требует большой гибкости средней части спины. Тонизирует позвоночник и органы брюшной полости, асана помогает в формировании правильного подъема стопы, избавляет от боли в пятках и пяточных шпор.",
        instructions: [
            "взгляд вперед вверх",
            "пятки к тазу",
            "пальцы рук и ног к голове",
            "толкать кисти вниз, приближая пальцы ног и пятки к полу",
        ]
    },
    {
        id: 61,
        img: yoga61,
        asanasName: "Rabbit Twist Pose",
        title:"Paryvritta_Sasangasana",
        asanasNameRus: "Поза Зайца в скрутке",
        asanasNameDe: "Wirbelsäulendrehung in Rückenlage",
        asanasNameBe: "Пастава Зайца ў скрутку",
        asanasNameSanskrit:"Paryvritta Sasangasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Паривритта Шашанкасана относится к простым скручиваниям и сама может быть начальным положением для многих асан. Но перед ее выполнением необходимо размять мышцы спины. Обязательно повторить скручивание в другую сторону.",
        instructions: [
            "таз над коленями, не смещен вперед",
            "скручивание в грудном отделе",
            "нижняя рука прижата к полу",
            "подъемы на полу",
            "вытягиваться в позвоночнике",
            "грудь ракрыта",
            "мышцы корпуса активны"
        ]
    },
    {
        id: 62,
        img: yoga62,
        asanasName: "Extended Child Pose",
        title:"Prasarita_Balasana",
        asanasNameRus: "Поза Ребенка Расширенная",
        asanasNameDe: "die Gestreckte Welpenhaltung",
        asanasNameBe: "Пастава Дзіця Пашыраная",
        asanasNameSanskrit:"Prasarita Balasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Баласана используется для отдыха, восстановления дыхания. Основная задача - успокоить ум и расслабить тело. Это идеальное положение для людей с большим животом, поскольку в Баласане их живот сжимается.",
        instructions: [
            "вытягивать и расслаблять позвоночник",
            "раздвигать лопатки в стороны и расслабить",
            "лоб в пол",
            "колени расставлены на ширине плеч",
            "полностью расслабить плечи",
            "вытянуть руки вперед",
            "ступни соединены в пальцах ног",
            "ятки немного раздвинуты"
        ]
    },
    {
        id: 63,
        img: yoga63,
        asanasName: "Ear Pressure Pose",
        title:"Karnapidasana",
        asanasNameRus: "Поза Ушей Между Коленями",
        asanasNameDe: "die Knie-zum-Ohr Pose",
        asanasNameBe: "Пастава Вушэй Паміж Каленямі",
        asanasNameSanskrit:"Karnapidasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Карнапидасана - перевернутая поза, схожа по воздействию с Халасаной, но требующая большей гибкости спины и шеи. Важно убирать давление с шеи. Рекомендуется для продвинутого уровня.",
        instructions: [
            "седалищные косточки вверх",
            "вытягивать позвоночник",
            "колени на полу",
            "таз над плечами",
            "вытягивать шею",
            "плечи от ушей",
            "пятки в потолок",
            "подъемы в пол"
        ]
    },
    {
        id: 64,
        img: yoga64,
        asanasName: "Warrior Pose III",
        title:"Virabhadrasana_III",
        asanasNameRus: "Поза Воина III",
        asanasNameDe: "der Krieger III",
        asanasNameBe: "Пастава Воіна III",
        asanasNameSanskrit:"Virabhadrasana III",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Вирабхадрасана III - базовая асана в йоге, которая часто встречается в практиках на проработку мышц бедер и ягодиц. Может быть трудной в удержании баланса. В этом случае рекомендуется развести руки в стороны самолетиком. Для продвинутого уровня - вытянуть руки вперед, не соединяя.",
        instructions: [
            "макушкой тянемся вперед",
            "живот вовнутрь",
            "таз закрыт",
            "мышцы бедра в тонусе",
            "колено вытянутой ноги вниз",
            "пятка тянется вверх и назад",
            "корпус и нога на одной линии",
            "ладони вместе",
            "коленные чашечки вверх",
            "пальцы поднятой ноги в пол",
            "нет прогиба в пояснице"
        ]
    },
    {
        id: 65,
        img: yoga43,
        asanasName: "Birch Pose",
        title:"Salamba_Sarvangasana",
        asanasNameRus: "Поза Березки",
        asanasNameDe: "die Birkenpose",
        asanasNameBe: "Пастава Бярозкі",
        asanasNameSanskrit:"Salamba Sarvangasana",
        level: "middle",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Саламба Сарвангасана выполняется с упором на плечи. Вверх поднимаем сначала согнутые ноги и только потом расправляем. Выходим из позы в Халасану или сгибая ноги в коленях, подтягивая к животу, аккуратно на спину.",
        instructions: [
            "убираем вес тела с шеи на плечи",
            "ноги и корпус на одной линии",
            "мышцы ног в тонусе",
            "локти стремятся друг к другу",
            "грудь к подбородку",
            "ладони к лопаткам",
            "вес тела на плечах",
        ]
    },
    {
        id: 66,
        img: yoga66,
        asanasName: "Seated Forward Bend",
        title:"Upavistha_Konasana",
        asanasNameRus: "Наклон Вперед Сидя",
        asanasNameDe: "Sitzender Winkel",
        asanasNameBe: "Нахіл Наперад Седзячы",
        asanasNameSanskrit:"Upavistha Konasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Упавишта Конасана. Асана раскрывает тазобедренные суставы, подготавливает к поперечному шпагату (Саммаконасана). Имеет вариации с руками назад или вперед, что способствует раскрытию плечевых суставов или вытяжению позвоночника.",
        instructions: [
            "живот к полу",
            "не скруглять спину",
            "бедра в контакте с полом",
            "квадрицепсы активны",
            "колени в потолок и назад",
            "пальцы ног вверх",
            "плечи от ушей, направлять к лопаткам",
            "лобковую кость к полу",
            "вытягивать позвоночник",
            "седалищные косточки направлять назад",
            "вытягивать ноги"
        ]
    },
    {
        id: 67,
        img: yoga67,
        asanasName: "Wind Release Pose",
        title:"Pawanmuktasana",
        asanasNameRus: "Поза Стихии Ветра",
        asanasNameDe: "die Windfreisetzungshaltung",
        asanasNameBe: "Пастава Ветру",
        asanasNameSanskrit:"Pawanmuktasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Паванмуктасана. Одна из базовых поз, может использоваться как разминочная поза. Укрепляет мышцы живота, способствует пищеварению.",
        instructions: [
            "позвоночник прижат к полу",
            "плечи от ушей",
            "нога прижата к животу",
            "живот вовнутрь",
            "взгляд вверх"
        ]
    },
    {
        id: 68,
        img: yoga68,
        asanasName: "Head On Knee Pose",
        title:"Janu_Sirsasana",
        asanasNameRus: "Поза Голова на коленях",
        asanasNameDe: "die Kopf-auf-Knie Pose",
        asanasNameBe: "Пастава Галава на каленях",
        asanasNameSanskrit:"Janu Sirsasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Джану Ширшасана. Помогает раскрытию тазобедренных суставов, вытягивает спину и заднюю поверхность. Готовит тело к более сложным асанам. Со стороны согнутой ноги корпус часто поднимается вверх, практикующий заваливается на сторону вытянутой ноги. Задача выравнивать грудь, направлять корпус вниз и плечи параллельно полу.",
        instructions: [
            "плечи от ушей",
            "плечи внешнее вращение",
            "грудь вперед",
            "позвоночник вытягивать",
            "сторону вытянутой ноги толкать назад, а сторону согнутой ноги толкать вперед",
            "седалищные косточки направлять назад",
            "колено согнутой ноги направлять к полу и назад",
            "подошва согнутой ноги прижата к бедру",
            "не заваливать корпус в сторону"
        ]
    },
    {
        id: 69,
        img: yoga69,
        asanasName: "Sage Twist Pose",
        title:"Marichyasana",
        asanasNameRus: "Поза Мудреца",
        asanasNameDe: "die Salbei-Twist Pose",
        asanasNameBe: "Пастава мудраца",
        asanasNameSanskrit:"Marichyasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Маричьясана. Асана улучшает гибкость плечевых и тазобедренных суставов. Происходит вытяжение позвоночника и задней поверхности бедра. Начинающим для захвата рекомендуется использовать ремень.",
        instructions: [
            "макушка вперед",
            "плечи от ушей",
            "выравнивать корпус, оба плеча параллельны полу",
            "вытягивать позвоночник",
            "стремиться соеденить руки за спиной",
            "выравнивать таз",
            "седалищные косточки вниз и назад",
            "ягодица согнутой ноги может не касаться пола",
            "бедро согнутой ноги направлять назад",
            "расстояние между стопой и бедром ок 15-20 см, пятка четко над коленом"
        ]
    },
    {
        id: 70,
        img: yoga70,
        asanasName: "One Legged Insect Pose",
        title:"Eka_Hasta_Bhujasana",
        asanasNameRus: "Поза Oдноногого Hасекомого",
        asanasNameDe: "die Einbeinige Insektenhaltung",
        asanasNameBe: "Пастава Казуркі з Адной Нагой",
        asanasNameSanskrit:"Eka Hasta Bhujasana",
        level: "middle",
        type: "standing asana",
        duration: 30,
        additionalImg: a4,
        description: "Эка Хаста Бхуджасана. Начинающие могут оставить вытянутую ногу на полу для опоры, поднимая только таз на руках. Часто эту асану тяжело освоить из-за анатомических особенностей - длины рук. В этом случае имеет смысл использовать блок.",
        instructions: [
            "нога обнимает руку",
            "живот вовнутрь",
            "выталкивать себя вверх",
            "вытягивать ногу, квадрицепс активен",
            "таз уводить назад, выравнивать",
            "подниматься за счет силы пресса",
            "пальцы активные",
            "не висеть на запястьях, распределять вес"
        ]
    },
    {
        id: 71,
        img: yoga71,
        asanasName: "Compass Pose",
        title:"Yantrasana",
        asanasNameRus: "Поза Компаса",
        asanasNameDe: "die Kompasspose",
        asanasNameBe: "Пастава Компаса",
        asanasNameSanskrit:"Yantrasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Янтрасана. Направлена на вытяжение боковых частей корпуса. Помогает улучшить гибкость плечевых и тазобедренных суставов.",
        instructions: [
            "шею вытягивать, не зажимать",
            "направлять грудную клетку вверх",
            "вытягивать позвоночник",
            "не зажимать бока",
            "внешнее вращение бедра согнутой ноги",
            "колено в пол",
            "плечо вытянутой руки мягко толкает вытянутую ногу",
            "внешнее вращение вытянутой ноги",
            "квадрицепс вытянутой ноги активен",
            "не висеть на руке",
            "обе седалищные косточки на полу"
        ]
    },
    {
        id: 72,
        img: yoga72,
        asanasName: "Cow Face Pose",
        title:"Gomukhasana",
        asanasNameRus: "Поза Коровьего Лица",
        asanasNameDe: "die Kuhgesichtshaltung",
        asanasNameBe: "Пастава Каровінага Твару",
        asanasNameSanskrit:"Gomukhasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Гомукхасана способствует подвижности плечевых суставов. Начинающим рекомендуется использовать блок под ягодицы.",
        instructions: [
            "макушка в потолок",
            "плечи вниз",
            "плечи внешнее вращение",
            "локоть поднятой руки вверх",
            "соединить пальцы рук за спиной",
            "раскрывать грудную клетку",
            "нижние ребра вовнутрь",
            "живот вовнутрь",
            "позвоночник вытягивать",
            "поясницу вытягивать",
            "одно колено четко над другим",
            "ноги активные",
            "стопы на одной линии и на равном расстоянии от бедер",
            "вес распределен на обе седалищные косточки"
        ]
    },
    {
        id: 73,
        img: yoga73,
        asanasName: "Camel Pose",
        title:"Ushtrasana",
        asanasNameRus: "Поза Верблюда",
        asanasNameDe: "Kamelhaltung",
        asanasNameBe: "Пастава Вярблюда",
        asanasNameSanskrit:"Ushtrasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Уштрасана - это прогиб, который требует раскрытия грудного отдела. В упрощенном варианте стопы можно поставить на пальцы и не опускать ладони на пятки, оставив руки на талии. Использовать блоки, устанавливая ладони на них.",
        instructions: [
            "взгляд поверх груди",
            "грудной отдел вверх",
            "плечи над запястьями",
            "лопатки к позвоночнику",
            "бедра и таз 90 градусов к полу",
            "подъемы стоп на полу",
            "колени на ширине плеч",
            "руками отталкиваемся от пяток"
        ]
    },
    {
        id: 74,
        img: yoga74,
        asanasName: "Staff Pose",
        title:"Dandasana",
        asanasNameRus: "Поза Посоха",
        asanasNameDe: "Stabhaltung",
        asanasNameBe: "Пастава Палкі",
        asanasNameSanskrit:"Dandasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Дандасана на первый взгляд простая и доступная асана. Однако начинающим может быть трудно удерживать прямую спину. Для этого под ягодицы используем блок, а руки уводим слегка назад, выравнивая корпус. Также можно присогнуть ноги в коленях. Для усложнения поднимаем руки вверх, а плче и лопатки прододжаем направлять к полу. Асана способствует улучшению осанки и раскрытию грудного отдела.",
        instructions: [
            "макушка в потолок",
            "плечи от ушей",
            "плечи назад и вниз",
            "спина прямая",
            "седалищные косточки прижаты к полу",
            "живот вовнутрь",
            "нижние ребра вовнутрь",
            "квадрицепсы активны",
            "взгляд перед собой",
            "ладони около таза", 
            "стопы вместе"
        ]
    },
    {
        id: 75,
        img: yoga75,
        asanasName: "Monkey God Pose",
        title:"Hanumanasana",
        asanasNameRus: "Поза Царя Обезьян",
        asanasNameDe: "Affengott Pose",
        asanasNameBe: "Пастава Вялікага Ваярскага Ваяка",
        asanasNameSanskrit:"Hanumanasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Хануманасана - одна из самых желаемых асан, по которой часто оценивают уровень гибкости практикующего. Как и любая другая сложная асана - это вопрос дисциплины в практике. Комплексы на освоение шпагата включают в себя работу совокупности из динамического переход из Ардха Хануманасаны в Ашва Санчаласану, статики в Уттхан Приштхасане, Уттхита Хаста Падангуштхасана (с захватом или без). Важно дополнить последовательность упражнениями на укрепление мышц ягодиц и бёдер — по передней и задней поверхности.",
        instructions: [
            "корпус выровнен",
            "плечи над тазом",
            "колено вверх, второе - в пол",
            "таз закрыт",
            "бедра напряжены",
            "пятка задней ноги к потолку",
            "пальцы ног передней ноги направлены в потолок",
            "задняя нога не поворачивается в сторону"
        ]
    },
    {
        id: 76,
        img: yoga76,
        asanasName: "Revolved Seated Angle Pose",
        title:"Parivrtta_Upavistha_Konasana",
        asanasNameRus: "Повернутая Поза Угла Сидя",
        asanasNameDe: "Gedrehte sitzende Winkelhaltung",
        asanasNameBe: "Павярнутая Сядзячая Пастава Кута",
        asanasNameSanskrit:"Parivrtta Upavistha Konasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Паривритта Упавишта Конасана - в этой позе происходит интенсивное вытяжение боковых корпуса и внутренних поверхностей бедер. Для упрощения использовать ремень, накинуть его на стопу, сделав мягкий наклон и оставить ноги прямыми.",
        instructions: [
            "вытяжение по всему боку",
            "седалищные косточки прижаты к полу",
            "колени обращены вверх",
            "мышцы ног в тонусе",
            "локоть верхней руки вверх",
            "стопы на себя",
            "разворот вверх в грудном отделе",
            "плечи от ушей"
        ]
    },
    {
        id: 77,
        img: yoga77,
        asanasName: "Flip Dog",
        title:"Chamatkarasana",
        asanasNameRus: "Поза Танцующей собаки",
        asanasNameDe: "Die Wilde Sache",
        asanasNameBe: "Пастава Дзікая Рэч",
        asanasNameSanskrit:"Chamatkarasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Чаматкарасана должна получаться без напряжения, легкой. Чтобы этого добиться необходимо раскрепощение грудного отдела. Часто из этой позы можно выполнить переход в позу моста (лука).",
        instructions: [
            "грудной отдел направлен к потолку",
            "вытяжение за рукой и назад",
            "руки выпрямлены в локте",
            "стопа вытянутой ноги полностью на полу ",
            "ладонь плотно прижата к полу"
        ]
    },
    {
        id: 78,
        img: yoga78,
        asanasName: "Upward Spread Feet PoseUpward Extended Feet Pose",
        title:"Urdhva_Prasarita_Padasana",
        asanasNameRus: "Поза с поднятыми вверх ногами",
        asanasNameDe: "Aufwärts Gestreckte Füße Pose",
        asanasNameBe: "Пастава з падрыхамі нагамі ўверх",
        asanasNameSanskrit:"Urdhva Prasarita Padasana",
        level: "beginner",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Урдхва Прасарита Падасана - в этой позе интенсивно работают ноги и пресс. Для упрощения позы можно опустить руки вдоль корпуса. Для усложнения позы - оставить ноги под углом 60 градусов.",
        instructions: [
            "Ноги 90 градусов к полу",
            "стопы на себя",
            "ладони раскрыты вверх",
            "поясница прижата к полу"
        ]
    },
    {
        id: 79,
        img: yoga79,
        asanasName: "Peacock Pose",
        title:"Mayurasana",
        asanasNameRus: "Поза Павлина",
        asanasNameDe: "die Pfauenhaltung",
        asanasNameBe: "Пастава Паўліна",
        asanasNameSanskrit:"Mayurasana",
        level: "advanced",
        type: "standing asana",
        duration: 30,
        additionalImg: null,
        description: "Маюрасана - продвинутый баланс на руках. Используется для укрепления корпуса и придания энергии телу. Для начинающих лучше использовать блоки под плечи, а пальцы стоп оставляем на полу.",
        instructions: [
            "вес тела смещается вперед",
            "локти сведены вместе",
            "пальцы рук назад",
            "ноги прямые в коленях",
            "баланс на руках",
            "угол в локтях более 90 градусов"
        ]
    },
    {
        id: 80,
        img: yoga80,
        asanasName: "Garland Pose",
        title:"Malasana_variation",
        asanasNameRus: "Поза Гирлянды",
        asanasNameDe: "die Girlandenpose",
        asanasNameBe: "Пастава Гірлянды",
        asanasNameSanskrit:"Malasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Маласана имеет терапевтический эффект для улучшения пищеварения, профилактики запоров. Помогает раскрытию тазобедренных суставов и гибкости голеностопа.",
        instructions: [
            "макушка вверх",
            "плечи вниз",
            "шея длинная",
            "ладони вместе",
            "вытягивать позвоночник",
            "поясница прямая",
            "живот вовнутрь",
            "локтями расталкивать колени в стороны и назад", 
            "стопы и колени в одном направлении",
            "раскрывать грудь",
            "грудина вверх",
        ]
    },
    {
        id: 81,
        img: yoga81,
        asanasName: "Half Bridge Pose",
        title:"Ardha_Urdhva_Dhanurasana",
        asanasNameRus: "Поза Полумост",
        asanasNameDe: "Schulterbrücke",
        asanasNameBe: "Пастава Паўмоста",
        asanasNameSanskrit:"Ardha Urdhva Dhanurasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Ардха Урдхва Дханурасана используется как легкий, мягкий прогиб в конце урока. Способствует вытяжению передней поверхности тела. Могут возникнуть сложности из-за недостаточного разогрева в течение практики.",
        instructions: [
            "колени над пятками",
            "таз вверх",
            "грудина к подбородку",
            "стопы прижаты и направлены друг к другу",
            "квадрицепс активный",
            "ягодичные мышцы активны"
        ]
    },
    {
        id: 82,
        img: yoga82,
        asanasName: "Side Plank",
        title:"Vasishthasana",
        asanasNameRus: "Боковая Планка",
        asanasNameDe: "Seitstütz",
        asanasNameBe: "Бакавая Планка",
        asanasNameSanskrit:"Vasishthasana",
        level: "middle",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Васихштасана прорабатывает мышцы кора, требует силы в руках. Может быть трудной в освоении из-за болей в запястьях. Для этого важно правильно устанавливать ладонь, распределяя вес.",
        instructions: [
            "вытяжение за макушкой",
            "тело на одной линии",
            "руки на одной линии",
            "таз не провисает",
            "ноги прямые",
            "стопа верхней ноги на нижней",
            "опорная рука 90 градусов к полу",
            "запястье опорной руки под плечом",
            "взгляд на верхнюю руку"
        ]
    },
    {
        id: 83,
        img: yoga83,
        asanasName: "Dancer Pose",
        title:"Nataradzhasana",
        asanasNameRus: "Поза Короля Танцев",
        asanasNameDe: "Tänzer",
        asanasNameBe: "Пастава Караля Танцаў",
        asanasNameSanskrit:"Nataradjhasana",
        level: "advanced",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Натараджасана - это сложный прогиб, который требует одновременно баланса, устойчивости. Если наклонять тело вперед, то теряется эффект от позы, который заключается в том, чтобы раскрывать грудной отдел.",
        instructions: [
            "стремление привести корпус в вертикальное положение",
            "взгляд на руку",
            "таз закрыт",
            "грудной отдел вперед и вверх",
            "бедро параллельно полу"
        ]
    },
    {
        id: 84,
        img: yoga84,
        asanasName: "Scorpion Pose",
        title:"Vrschikasana",
        asanasNameRus: "Поза Скорпиона",
        asanasNameDe: "Skorpion",
        asanasNameBe: "Пастава Скарпіёна",
        asanasNameSanskrit:"Vrschikasana",
        level: "advanced",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Вришчикасана - это баланс на предплечьях со сложным прогибом назад. Начинающим не рекомендуется выполнять эту позу.",
        instructions: [
            "выполнение этой асаны требует многолетней подготовки под руководством квалифицированного учителя"
        ]
    },
    {
        id: 85,
        img: yoga85,
        asanasName: "Cat Cow Pose",
        title:"Bitilasana_Marjaryasana",
        asanasNameRus: "Поза Кошки-Коровы",
        asanasNameDe: "Katzen-Kuh Pose",
        asanasNameBe: "Пастава Кошкі-Каровы",
        asanasNameSanskrit:"Bitilasana Marjaryasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Поза Марджарьясана – Битиласана - это комбинация из двух асан, которые очень часто выполняют вместе для разогрева и придания гибкости позвоночнику. Она помогает укрепить руки, ноги и мышцы корпуса, стимулирует органы брюшной полости.",
        instructions: [
            "вдох - поза коровы", 
            "выдох - поза кошки"
        ]
    },
    {
        id: 86,
        img: yoga86,
        asanasName: "Balancing Table Pose",
        title:"Dandayamna_Bharmanasana",
        asanasNameRus: "Баланс Поза Стола",
        asanasNameDe: "Balancierende Tischhaltung",
        asanasNameBe: "Баланс Пастава Стала",
        asanasNameSanskrit:"Dandayamna Bharmanasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Дандайамана Бхармана - восстанавливающая поза йоги. Укрепляет мыщцы корпуса, бедра и поясницу.",
        instructions: [
            "колено согнутой ноги на одной линии с бедром",
            "пятки подняты",
            "ноги сильные",
            "живот вовнутрь",
            "плечи от ушей",
            "вытяжение позвоночника",
            "таз активный",
            "взгляд вниз"
        ]
    },
    {
        id: 87,
        img: yoga87,
        asanasName: "Downward Upward Dog Pose",
        title:"Adho_Urdhva_Mukha_Svanasana",
        asanasNameRus: "Собака Мордой Вниз-Вверх",
        asanasNameDe: "Herabschauender Aufschauender Hund",
        asanasNameBe: "Сабака Мордай Уніз-Уверх",
        asanasNameSanskrit:"Adho Urdhva Mukha Svanasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Это сет - классическая связка в хатха-йоге. Часто добавляют в эту связку планку между двуми позами.",
        instructions: [
            "на вдохе - вход в позу Собака Мордой Вверх",
        ]
    },
    {
        id: 88,
        img: yoga88,
        asanasName: "Gate Pose",
        title:"Parighasana",
        asanasNameRus: "Поза Ворот",
        asanasNameDe: "Torhaltung",
        asanasNameBe: "Пастава Варот",
        asanasNameSanskrit:"Parighasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Паригхасана - растягивает боковые мыщцы, укрепляет ягодичные мышцы, способствует развитию баланса. Раскрывает бедра, что является одной из целей данной позы.",
        instructions: [
            "стопа на одной линии с коленом",
            "грудная клетка вверх",
            "вытяжение позвоночника",
            "вытяжение боковой поверхности",
            "пупок втянут",
            "глубокий вдох для наклона",
            "таз на себя и вперед, активный"
        ]
    },
    {
        id: 89,
        img: yoga89,
        asanasName: "Standing Balance One Leg Raised",
        title:"Utthita_Eka_Padasana",
        asanasNameRus: "Равновесие стоя с поднятой ногой",
        asanasNameDe: "Stehendes Gleichgewicht ein Bein angehoben",
        asanasNameBe: "Раўнавага стоячы з паднятай нагой",
        asanasNameSanskrit:"Utthita Eka Padasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Уттхита Эка Падасана - это начальная поза, которая относится к позам на проработку баланса и мышц пресса. Разгибание ноги до 90 градусов к полу требует среднего уровня подготовки, поэтому для начинающих имеет смысл использовать ремни. В позе укрепляются бедра и квадрицепсы.",
        instructions: [
            "пресс напряжен",
            "плечи от ушей",
            "пупок втянут",
            "взгляд зафиксирован на одной точке",
            "макушка вверх",
            "позвоночник вытянут"
        ]
    },
    {
        id: 90,
        img: yoga90,
        asanasName: "Easy Pose",
        title:"Sukhasana",
        asanasNameRus: "Легкая Поза",
        asanasNameDe: "Einfache Pose",
        asanasNameBe: "Лёгкая Пастава",
        asanasNameSanskrit:"Sukhasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Сукхасана - это сидячая поза начального уровня с легким скрещиванием ног, прямой спиной и шеей. Однако для начинающих она может быть первое время дискомфортной из-за правила ровного удерживания спины. Она постепенно раскрывает бедра, укрепляет спину, приводя позвоночник в правильное положение.",
        instructions: [
            "макушка вверх",
            "плечи от ушей",
            "позвоночник вытянут",
            "колени обращены к полу",
            "внешнее вращение бедер",
            "седалищные косточки на полу",
            "вес распределен равномерно"
        ]
    },
    {
        id: 91,
        img: yoga91,
        asanasName: "Half Squat Pose",
        title:"Ardha_Malasana",
        asanasNameRus: "Поза Полуприседа",
        asanasNameDe: "Halbe Kniebeuge",
        asanasNameBe: "Пастава на паўпрысяданні",
        asanasNameSanskrit:"Ardha Malasana",
        level: "middle",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Ардха Маласана улучшает гибкость суставов, чувство баланса и мыщцы кора. Особенно рекомендуется для тех практикующих, кто занимается бегом.",
        instructions: [
            "макушка вверх",
            "плечи от ушей",
            "пальцы ног прямой ноги вверх",
            "стопа согнутой ноги стремится к полу",
            "позвоночник вытянут",
            "колено согнутой ноги толкаем назад",
            "живот вовнутрь",
            
        ]
    },
    {
        id: 92,
        img: yoga92,
        asanasName: "One-legged King Pigeon Pose",
        title:"Eka_Pada_Rajakapotasana",
        asanasNameRus: "Поза одноногого Голубя",
        asanasNameDe: "Einbeinige Königstaube",
        asanasNameBe: "Пастава Галуба з адной нагой",
        asanasNameSanskrit:"Eka Pada Rajakapotasana",
        level: "advanced",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Эка Пада Райякапотасана - это поза для практики среднего уровня. Перед практикой необходима разминка для суставов. Поза нацелена на сгибатели бедра, укрепление и растяжение квадрицепсов. Прогиб назад помогает ракрытию грудной клетки, улучшая осанку.",
        instructions: [
            "взгляд вверх",
            "ягодицы напряжены",
            "согнутое колено активно, на одной линии с бедром",
            "стопа приближена максимально к промежности",
            "легкий прогиб назад",
            "глубокий вдох для прогиба назад"
        ]
    },
    {
        id: 93,
        img: yoga93,
        asanasName: "Side Bending Pose",
        title:"Urdhva_Hastasana_Ardha_Chandrasana",
        asanasNameRus: "Поза бокового изгиба",
        asanasNameDe: "Seitliche Biegung",
        asanasNameBe: "Пастава бакавога схілу",
        asanasNameSanskrit:"Urdhva Hastasana Ardha Chandrasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Эта поза начального уровня. Часто используется в начале практики для разогрева. В ней особое внимание уделяется балансу и вытяжению боковых мышц.",
        instructions: [
            "макушка тянется за рукой",
            "мышцы всего тела активны",
            "пупок вовнутрь",
            "живот вовнутрь",
            "вес тела распределен равномерно на стопы",
            "тело в одной плоскости, не заваливается назад или вперед"
        ]
    },
    {
        id: 94,
        img: yoga94,
        asanasName: "Half Circle Pose",
        title:"Ardha_Mandalasana",
        asanasNameRus: "Поза Полукруга",
        asanasNameDe: "Halbkreis Pose",
        asanasNameBe: "Пастава Паўкола",
        asanasNameSanskrit:"Ardha Mandalasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Ардха Мандаласана - поза баланса на одном колене. Раскрывает бедра, укрепляет мышцы диафрагмы, удлиняет поясничные мышцы, растягивает позвоночник, улучшая его гибкость.",
        instructions: [
            "нога вытянута",
            "рука в полукруге над головой",
            "опорная рука может быть слегка согнута в локте", 
            "плечи от ушей",
            "живот вовнутрь"
        ]
    },
    {
        id: 95,
        img: yoga95,
        asanasName: "Dancer Pose",
        title:"Nataradjhasana_variation",
        asanasNameRus: "Поза Танцора",
        asanasNameDe: "Tänzer Pose",
        asanasNameBe: "Пастава Караля Танцаў",
        asanasNameSanskrit:"Nataradjhasana",
        level: "advanced",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Натараджасана в данном исполнении - это сложный прогиб с захватом ступни обеими руками, который требует одновременно баланса и устойчивости. Если наклонять тело вперед, то теряется эффект от позы, который заключается в том, чтобы раскрывать грудной отдел.",
        instructions: [
            "стремление привести корпус в вертикальное положение",
            "взгляд вверх",
            "таз закрыт",
            "грудной отдел вперед и вверх",
            "бедро параллельно полу",
            "захват ступни обеими руками",
            "вес тела на балансирующей ноге"

        ]
    },
    {
        id: 96,
        img: yoga96,
        asanasName: "Vishnu Pose",
        title:"Anantasana",
        asanasNameRus: "Поза Вишну",
        asanasNameDe: "Vishnu Pose",
        asanasNameBe: "Пастава Вішну",
        asanasNameSanskrit:"Anantasana",
        level: "middle",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Поза Вишну или поза подъема ног с наклоном в сторону - это поза, обеспечивающая растяжку подколенных сухожилий, ног и внутренней поверхности бедер. Для начинающих умеет смысл использовать ремни для поддержки ноги.",
        instructions: [
            "внешнее вращение бедер",
            "лопатки вместе",
            "плечи от ушей",
            "подбородок от груди",
            "стопы активны",
            "пупок вовнутрь",
            "поднятие ноги на вдохе"
        ]
    },
    {
        id: 97,
        img: yoga97,
        asanasName: "Candy Cane Pose",
        title:"Ardha_Chandrasana_Chapasana",
        asanasNameRus: "Поза Леденца",
        asanasNameDe: "Zuckerrohr Pose",
        asanasNameBe: "Пастава Цукерачнай Тросці",
        asanasNameSanskrit:"Ardha Chandrasana Chapasana",
        level: "middle",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Ардха Чандра Чапасана - это поза на удержание баланса. Задействуются мышцы ягодиц, сгибателей бедра, поясничной мышцы. В этой позе две стороны тела работают по-разному, повороты тела следует выполнять тщательно и осознанно, чтобы избежать травм и падений.",
        instructions: [
            "стопа прижата к полу",
            "вес тела равномерно на подушечку стопы и пятку",
            "нижняя рука полностью выпрямлена",
            "позвоночник скручен без округления или выгибания при наклоне вперед",
            "колено согнутой ноги направлено назад",
            "стопа от бедер",
            "взгляд вверх"
        ]
    },
    {
        id: 98,
        img: yoga98,
        asanasName: "Revolved Chair Pose",
        title:"Parivrtta_Utkatasana",
        asanasNameRus: "Поза скрученного Стула",
        asanasNameDe: "Gedrehte Stuhlhaltung",
        asanasNameBe: "Пастава Паваротнага Крэсла",
        asanasNameSanskrit:"Parivrtta Utkatasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Поза скрученного Стула - это скрутка, которая хорошо влияет на органы пищеварения с одновременным балансом нижней части тела. Хороша в уроках, направленных на повышение устойчивости бедер и таза.",
        instructions: [
            "ягодицы подкручены",
            "ноги вместе",
            "колени не выходят за линию стоп",
            "бедра внутреннее вращение",
            "плечи от ушей",
            "шея длинная",
            "пупок вовнутрь",
            "глубокий выдох для поворота",
            "локоть отталкивается от колена"
        ]
    },
    {
        id: 99,
        img: yoga99,
        asanasName: "Revolved Lunge Pose",
        title:"Parivrtta_Anjaneyasana_variation",
        asanasNameRus: "Поза с низким выпадом",
        asanasNameDe: "Gedrehtes Knie mit niedrigem Ausfallschritt",
        asanasNameBe: "Нізкі выпад у паварочвальнай позе",
        asanasNameSanskrit:"Parivritta Anjaneyasana",
        level: "beginner",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Паривритта Анджанеясана «Колено на полу» - это поза, растягивающая большие ягодичные мышцы, скрутка помогает раскрытию грудной клетки и растяжке всех мышц спины. Как и все скрутки, эта поза хорошо влияет на пищеварение.",
        instructions: [
            "живот к бедру",
            "ладонь прижата к полу",
            "на выдохе - поворот тела и головы",
            "взгляд вверх",
            "верхняя рука прямая"
        ]
    },
    {
        id: 100,
        img: yoga100,
        asanasName: "One-Legged Wheel Pose",
        title:"Eka_Pada_Urdhva_Dhanurasana",
        asanasNameRus: "Поза колеса на одной ноге",
        asanasNameDe: "Einbeinige Radhaltung",
        asanasNameBe: "Пастава Кола на адной назе",
        asanasNameSanskrit:"Eka Pada Urdhva Dhanurasana",
        level: "advanced",
        type: "seated asana",
        duration: 30,
        additionalImg: null,
        description: "Эка Пада Урдхва Дханурасана - это продвинутая поза, прогиб. Не рекомендуется для начинающих. Поза значительно улучшает гибкость позвоночника, одновременно требуя поддержания баланса на одной ноге.",
        instructions: [
            "вход в позу начинается с позы Колеса (Мостика)",
            "ладони шире друг от друга",
            "нога на полу и ладони образуют треугольник",
            "колено поднятой ноги к груди и потом вверх",
            "плечи от ушей"            
        ]
    },







]
    
        
    

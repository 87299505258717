//здесь мы отображаем те асаны, которые выбраны для урока по кнопке add to lesson из Asana.js
//отображение по id выбранного:
//используем метод find, чтобы сравнить id из объекта 
// //console.log(lessonPose) и объекта  <Asana/> console.log(asana);

import { data } from "../../data"
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import '../../Styles/Asana.css';

//import Button from 'react-bootstrap/Button';
import { BsInfoCircle } from "react-icons/bs";
import { BsXLg } from "react-icons/bs";
import {  removePoseFromLesson } from "../../redux/lessonSlice";
import AddDurationPose from "./AddDurationPose";
//dnd-kit library
import { useDraggable } from '@dnd-kit/core';
// Импортируем хук для перевода
//import { useTranslation } from 'react-i18next'; 




const LessonPose = ({pose}) =>{
    
    const asanasInLesson = data.find(asana => asana.id === pose.poseId)
    const dispatch = useDispatch();
    //const { t } = useTranslation(); // Используем хук для получения функции перевода


    //dnd kit
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        setActivatorNodeRef,
    } = useDraggable({
        id: pose.id.toString(),
    });
    //dnd kit
    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition,
    };
    

    return(
        <div ref={setNodeRef}
        {...attributes}
        style={style}
        className="AsanaCard ">

            <div className="AsanaCardStroke shadow p-3 mb-3 bg-white rounded">

                <div className="LessonAddDurationRemoveButton">
                    <BsXLg  style={{ width: 15, height: 15 }}
                            onClick={() =>dispatch(removePoseFromLesson( {lessonPoseId: pose.id} ))}
                            
                    />

                    <Link to={`/${asanasInLesson.title}`} className="Info">
                        <BsInfoCircle  style={{ width: 15, height: 15 }}  />
                    </Link>

                </div>

                <div ref={setActivatorNodeRef}
                {...listeners}>
                    <img src={asanasInLesson.img} alt="asana" className="AsanaImage"/>
                    <p className="AsanaP FONT"> {asanasInLesson.asanasNameSanskrit} </p>
                    {/* <p className="AsanaP" > {t(`${asanasInLesson.id}AsanaName`, asanasInLesson.asanasName)}</p> */} 
                </div>

            </div>

            <div>
                <div className="addDurationPosition">                    
                    <AddDurationPose 
                        lessonPoseId={pose.id}
                        initialDuration={pose.duration}/>
                </div>
            </div>
        </div>
    )
}

export default LessonPose

/*                <Button onClick={() =>dispatch(removePoseFromLesson( {lessonPoseId: pose.id} ))} 
variant="dark"
className="MobileButton">
REMOVE
</Button>
*/



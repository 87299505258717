import f0 from "../../myAssets/f0.png"
import f1 from "../../myAssets/f1.png"
import f2 from "../../myAssets/f2.png"
import f4 from "../../myAssets/f4.png"
import f5 from "../../myAssets/f5.png"
import f6 from "../../myAssets/f6.png"
import f7 from "../../myAssets/f7.png"
import f8 from "../../myAssets/f8.png"
import f9 from "../../myAssets/f9.png"
import f10 from "../../myAssets/f10.png"

import { useTranslation } from 'react-i18next';


import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const Features = () =>{
    const { t } = useTranslation();
    const feature = [
        {
            img: f0,
            title: t('features.yogaPosesTitle'),
            description: t('features.yogaPosesDesc')
        },
        {
            img: f1,
            title: t('features.dragDropTitle'),
            description: t('features.dragDropDesc')
        },
        {
            img: f2,
            title: t('features.saveTitle'),
            description: t('features.saveDesc')
        },
        {
            img: f4,
            title: t('features.pdfTitle'),
            description: t('features.pdfDesc')
        },
        {
            img: f5,
            title: t('features.feaTitle'),
            description: t('features.feaDesc')
        },
        {
            img: f6,
            title: t('features.playTitle'),
            description: t('features.playDesc')
        },
        {
            img: f7,
            title: t('features.rulesTitle'),
            description: t('features.rulesDesc')
        },
        {
            img: f8,
            title: t('features.langTitle'),
            description: t('features.langDesc')
        },
        {
            img: f10,
            title: t('features.sanskTitle'),
            description: t('features.sanskDesc')
        },
        {
            img: f9,
            title: t('features.syncTitle'),
            description: t('features.syncDesc')
        },
        
    ]

    const featuresRef = useRef([]);

    useEffect(() => {
        featuresRef.current.forEach((el, index) => {
            gsap.fromTo(el, 
                { y: 50, opacity: 0 }, // начальное состояние элемента
                {
                    y: 0, // конечное положение y (в исходное состояние)
                    opacity: 1, // полностью непрозрачный
                    duration: 0.5, // продолжительность анимации
                    delay: index * 0.15, // задержка начала анимации, чтобы создать "эффект домино"
                    scrollTrigger: {
                        trigger: el,
                        start: 'top bottom-=150', // анимация стартует, когда элемент на 150px выше нижней части viewport
                        toggleActions: 'play none none none',
                        //markers: true
                    }
                }
            );
        });
    }, []);

    return(
        <div className="container-fluid px-2 justify-content-center featureMargin" >
            <h2 className="pb-2 text-center FeatureHeader"> {t('features.header')} </h2>
            <h5 className="text-body-secondary text-center mb-5 FontSecond">{t('features.subheader')}</h5>
    
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5  justify-content-center  ">
            {feature.map((feature, index) => (
                <div className="col d-flex flex-column align-items-center text-start p-5  shadow p-3 mb-5 bg-white rounded feature-card" 
                    key={index}
                    ref={el => featuresRef.current[index] = el} >
                        
                    <div className="mb-2">
                        <img src={feature.img} alt="Feature Icon" className="img-fluid" style={{ width: '150px', height: '150px', objectFit: 'contain' }} />
                    </div>
                    <h4 className="fw-bold mb-1  text-body-emphasis fixed-height-h4 text-start FONT">{feature.title}</h4>
                    <p className="text-body-secondary text-start  ">{feature.description}</p>
                </div>
            ))}
        </div>

        </div>
    );
    }
export default Features;



import { useForm, ValidationError } from '@formspree/react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import { useTranslation } from "react-i18next"



function ContactForm() {
    //состояние для чтения name, emeil, textarea, чтобы принимать только заполненные полностью формы
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    })
    //перевод
    const { t } = useTranslation()

    //состояние из formspree.io
    const [state, handleSubmit] = useForm("xwkgzbrv");

    //ответное сообщение, если посылка прошла успешно
    if (state.succeeded) {
        return <p>{t("formComponent.thankYou")}</p>;
    }

    //функция для отслеживания изменений в name, email, message
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prev => ({
            ...prev,
        [name]: value
        }))
    }
    //функция, указывающая на то, что все поля должны быть заполнены
    const isFormValid = formData.name && formData.email && formData.message



    return (
        <Form onSubmit={handleSubmit} id="fs-frm" name="simple-contact-form" className='container'>
            <Form.Group id="fs-frm-inputs" className="mb-3 FontOne">
                <Form.Label htmlFor="full-name">
                    {t("formComponent.yourName")}
                </Form.Label>
                <Form.Control 
                    type="text" 
                    name="name" 
                    id="full-name" 
                    placeholder={t("formComponent.yourName")}
                    required=""
                    value={formData.name}
                    onChange={handleChange}
                    className='mb-3 FontTwo'
                />
                <Form.Label htmlFor="email">
                    {t("formComponent.yourEmail")}
                </Form.Label>
                <Form.Control
                    id="email"
                    type="email" 
                    name="email"
                    placeholder="email@email.com"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className='mb-3 FontTwo'

                />
                <ValidationError 
                    prefix="Email" 
                    field="email"
                    errors={state.errors}
                />
                <Form.Label htmlFor="message">
                    {t("formComponent.yourMessage")}
                </Form.Label>
                <Form.Control
                    id="message"
                    name="message"
                    required
                    as="textarea"
                    value={formData.message}
                    onChange={handleChange}
                    className='mb-3 FontTwo'

                />
                <ValidationError 
                    prefix="Message" 
                    field="message"
                    errors={state.errors}
                />
                <Button type="submit" disabled={!isFormValid || state.submitting} variant='dark' className='FontTwo'>
                    {t("formComponent.sendButton")}
                </Button>
        </Form.Group>
        </Form>
    );
}
export default ContactForm;
import Asana from "./Asana";
import { useTranslation } from "react-i18next"
import Loader from "./Website/Loader";


function YogaCards( {yogaCardProp} ){

    const { t } = useTranslation()

    // Если данные загружаются, отображай лоадер
    if (!yogaCardProp) {
        return <Loader />;
    }    

    return(
        <div >
            <p className="container-fluid FONT" > {yogaCardProp.length} {t('searchAsanas.asanas100')} </p>
        <div className="cards"  >
            { yogaCardProp.map((asana,id) =>{
                return(
                    <div key={id} >
                        <Asana asana={asana} />
                    </div>
                )
            } )}

        </div>
        </div>

    )
}
export default YogaCards;




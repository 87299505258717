import { useSortable } from '@dnd-kit/sortable';

export const SortableItem = ({ id, children }) => {
    const { 
        attributes,
        setNodeRef,
        transform,
        transition } = useSortable({ id });

    //a typical style from dnd-kit documentation
    const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
    touchAction: "none" //<-- important in order to right work sortableItem on sensor Screens
    };

    return (
        <div
            ref={setNodeRef} 
            {...attributes} 
            style={style}>
        {children}
        </div>
    );
};




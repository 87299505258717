//документ, определяющий структуру PDF урока
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer"
import { data as yogaPosesData } from '../../data'

// Регистрация шрифтов
Font.register({
    family: 'Philosopher',
    src: require('../../myAssets/Philosopher-Regular.ttf'),
    });

const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        paddingTop: 30,
        paddingBottom: 30,
    },
    section: {
        margin: 11,
        padding: 11,
        width: "12%", 
    },
    image: {
        width: "100%", // Ширина картинки равна ширине секции
        height: "auto", // Высота автоматически рассчитывается
    },
    text: {
        fontFamily: 'Philosopher',
        marginTop: 10, 
        fontSize: 10, 
        width: "100%", // Ширина текста равна ширине секции
        textAlign: "center", 
    },
    headerText: { 
        position: 'absolute',
        fontSize: 12,
        top: 10, // Позиционируем текст вверху страницы
        width: "100%",
        textAlign: "center",
        color: "grey",
    },
    footerText: {
        position: 'absolute',
        fontSize: 12,
        bottom: 10,
        marginTop: 5,
        width: "100%",
        textAlign: "center",
        color: "grey",
    },
});

const StuctureLessonPDF = ({ lessonPose  }) => {
    // Функция для поиска данных асаны по poseId
    const getPoseData = (poseId) => yogaPosesData.find(pose => pose.id === poseId);

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Text style={styles.headerText}>YogaGirlsLessonPlan.com</Text>
                {lessonPose.map((pose, index) => {
                    const poseData = getPoseData(pose.poseId);
                    return (
                        <View key={index} style={styles.section}>
                            <Image src={poseData.img} style={styles.image} alt="old" />
                            <Text style={styles.text} >{poseData.asanasNameSanskrit}</Text>
                        </View>
                    );
                })}
                <Text style={styles.footerText}>YogaGirlsLessonPlan.com</Text>
            </Page>
        </Document>
    );
};
export default StuctureLessonPDF;
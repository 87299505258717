const Progressbar = ({ currentIndex, totalPoses }) => {
    // Рассчитываем прогресс так, чтобы на первой позе прогресс был 0%
    const progress = (currentIndex / (totalPoses -1)) * 100;

    const style = {
        width: `${progress}%`,
        backgroundColor: '#332D2D',
        height: '5px',
        borderRadius: '5px',
        transition: 'width 0.5s ease-in-out'
    };

    return (
        <div style={{ width: '100%', backgroundColor: '#D3D3D3', borderRadius: '5px' }}>
            <div style={style}></div>
        </div>
    );
};

export default Progressbar;





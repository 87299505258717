import { useTranslation } from "react-i18next"
import { Dropdown, Form } from "react-bootstrap"
import { useState } from "react";


const LanguageDropdown = ({ onSelect }) =>{

    const { i18n } = useTranslation()

    //состояние для выбора языка и динамического отображения 
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setSelectedLanguage(language); //отображаем выбранный язык
        if (onSelect) onSelect(); // Вызов переданного пропса onSelect в NavbarYG.js
    };

    const languageNameMap = {
        en: 'EN',
        be: 'BY',
        ru: 'RU',
        de: 'DE'
    };

    return (
        <div>
            <Form>
                <Dropdown >
                    <Dropdown.Toggle variant="light" id="dropdown-basic"  >
                        {languageNameMap[selectedLanguage]}
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="end" >
                        <Dropdown.Item onClick={() => changeLanguage("en")} >English - EN</Dropdown.Item>
                        <Dropdown.Item onClick={() => changeLanguage("be")} >Беларуская - BY</Dropdown.Item>
                        <Dropdown.Item onClick={() => changeLanguage("ru")} >Русский - RU</Dropdown.Item>
                        <Dropdown.Item onClick={() => changeLanguage("de")} >Deutsch - DE</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Form>
        </div>
    );
};
export default LanguageDropdown;

import { useEffect } from 'react';
import { useNavigate } from 'react-router';

const PrivatePolicyPage = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        document.title = "Privacy Policy | Yoga Practice";
    }, []);

    const handleGoBack = () => {
        navigate(-1);
    }

    return(
        <div>
            <button 
            onClick={handleGoBack}
            style={{
                padding: '10px 20px',
                backgroundColor: '#212529',
                color: '#FFF',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                marginBottom: '20px',
            }}
            >
                Back
            </button>

            <h4>PRIORITY OF YOUR PRIVACY</h4>
            <p>At our Yoga Practice application, your privacy is of utmost importance. This document outlines the types of information we collect, how it is used, and with whom it may be shared. This Privacy Policy does not apply to third-party practices that are outside of our control.</p>

            <h5>CONSENT</h5>
            <p>By using our application, you hereby consent to our Privacy Policy and agree to its terms.</p>

            <h5>INFORMATION WE COLLECT</h5>
            <p>We collect only the personal information that you voluntarily provide in order to use the functionality of our application:</p>
            <ul>
                <li>Email address: to save your preferences and restore your data.</li>
                <li>Selected yoga poses: to track and save your progress and preferences.</li>
            </ul>

            <h5>HOW WE USE YOUR INFORMATION</h5>
            <p>We use the information we collect in various ways, including to:</p>
            <ul>
                <li>Save your selected yoga poses.</li>
                <li>Provide convenient access to your personal account and preferences.</li>
                <li>Analyze and improve the application functionality to offer a more personalized experience.</li>
                <li>Allow you to delete your data by sending a request.</li>
            </ul>

            <h5>STORAGE OF INFORMATION</h5>
            <p>We store data both in the local storage (LocalStorage) of your device and in our database on our server. This data is used for:</p>
            <ul>
                <li>Saving your progress and selected yoga poses.</li>
                <li>Providing access to your personal account and customizing your experience in the application.</li>
                <li>Speeding up load times and improving application functionality.</li>
            </ul>
            <p>The information stored in the database includes your email address and the yoga poses you have selected or saved. This data is used solely to provide you with a personalized experience. We do not share this data with third parties without your consent.</p>

            <h5>USER RIGHTS</h5>
            <p>If you are a resident of the European Economic Area (EEA), you have the right to:</p>
            <ul>
                <li>Request access to your personal information.</li>
                <li>Correct or delete your information.</li>
            </ul>
            <p>Please contact us if you would like to exercise these rights.</p>

            <h5>CHANGES TO THE POLICY</h5>
            <p>We may update this Privacy Policy to reflect changes in our practices or to comply with new legal or regulatory requirements. We recommend checking this page periodically for updates.</p>

            <h5>CONTACT US</h5>
            <p>If you have any questions regarding our Privacy Policy, please contact us through the contact form on the application.</p>        </div>
    )
}
export default PrivatePolicyPage;
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { TbUser } from "react-icons/tb";
import { Button } from "react-bootstrap";

const LoginButton = () => {
    //isAuthenticated - прописанное условие, значищее, что пользователь зашел в аакаунт
    const { loginWithRedirect, isAuthenticated } = useAuth0();

    //!isAuthenticated && <-- если юзер не вошел в аккаунт, покажи Log IN
    return (
        !isAuthenticated && (
            <Button 
                onClick={() => loginWithRedirect()}
                variant="dark"
                className="me-3"> 
                <TbUser /> 
                Log In
            </Button>
        )
    )
};

export default LoginButton;
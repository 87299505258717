//форма для сохранения пользователем своего урока
import { useState } from "react";
import { Button, Tooltip, OverlayTrigger, Form, Col, Row } from "react-bootstrap"
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next"


const LessonUserForm = ( {onSave, lessonPose } ) =>{
    const { user } = useAuth0();

    // Получаем текущую дату и преобразуем ее в строку в формате "YYYY-MM-DD"
    const currentDate = new Date().toISOString().slice(0, 10);
    //данные из input
    const [lessonForm, setLessonForm] = useState({
        name: "",
        level: "beginner", // начальное значение уровня 
        date: currentDate, // Устанавливаем текущую дату по умолчанию
    })

    // состояние для управления видимостью тултипа
    const [showTooltip, setShowTooltip] = useState(false);

    //состояние для кнопки SAVE MY LESSON
    const [isSaved, setIsSaved] = useState(false);

    //функция для изменения состояния в input формах
    const handleInputOnChange = (e) => {
        const {name, value} = e.target;
        setLessonForm((prevState) => ({
            ...prevState,
            [name]:value,
        }))
        setIsSaved(false); // Сбрасываем состояние isSaved при каждом изменении
    }

    //функция сохранения урока SAVE MY LESSON
    const saveLesson = () =>{
        //проверка авторизации пользователя
        if (!user) {
            // Показываем тултип, если пользователь не авторизован
            setShowTooltip(true);
            // Через некоторое время скрываем тултип
            setTimeout(() => setShowTooltip(false), 5000);
            return;
        }
        // Формируем объект урока, где poses теперь массив идентификаторов асан
        const lessonData = { 
            ...lessonForm, 
            poses: lessonPose.map(pose => ({ poseId: pose.poseId, duration: pose.duration })), // что poseId - это идентификатор асаны
        };
    
        // Отправляем POST запрос на сервер для сохранения урока
        axios.post(`https://yogagirls-backend.onrender.com/${user.sub}/saveLesson`, lessonData)
        //axios.post(`http://localhost:4000/${user.sub}/saveLesson`, lessonData)

        .then(response => {
                // Обрабатываем успешное сохранение урока
                //console.log("Урок сохранен:", response.data);
                // Вызываем onSave для обновления состояния, если это необходимо
                onSave(response.data);
                setIsSaved(true); // Обновляем состояние, указывая, что урок был сохранен
            })
            .catch(error => {
                // Обрабатываем возможную ошибку при запросе
                console.error("Ошибка при сохранении урока:", error);
            });
    }

    // Функция для создания тултипа
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("tooltip.authForSaving")}
        </Tooltip>
    );

    //translation
    const { t } = useTranslation()


    return (
        <div className="container-fluid mt-3">
            <Form>
                <Form.Group>
                    <Form.Control
                        type="text"
                        name="name"
                        placeholder= {t("lesson.placeholderName")} 
                        value={lessonForm.name}
                        onChange={handleInputOnChange}
                        maxLength="100"
                    />
                </Form.Group>
                <Row className="mt-3 mb-3">
                    <Col xs={6}>
                        <Form.Group>
                            <Form.Control
                                as="select"
                                name="level"
                                value={lessonForm.level} 
                                onChange={handleInputOnChange}
                            >
                                <option value="beginner"> {t("levels.beginner")} </option>
                                <option value="middle">{t("levels.middle")}</option>
                                <option value="advanced">{t("levels.advanced")}</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6}>
                        <Form.Group>
                            <Form.Control
                                type="date"
                                name="date"
                                value={lessonForm.date}
                                onChange={handleInputOnChange}
                            />
                        </Form.Group>
                    </Col>
                    </Row>
            </Form>

                    <div className="d-flex justify-content-start">
                        <OverlayTrigger
                            show={showTooltip}
                            placement="top"
                            overlay={renderTooltip}>
                            <Button
                                onClick={saveLesson}
                                variant="dark"
                                disabled={!lessonForm.name.trim() || lessonPose.length === 0} // Кнопка будет неактивной, если имя урока пустое или состоит только из пробелов
                            >
                            {isSaved ? t('lesson.savedButton') : t('lesson.saveButton')}  
                            </Button>
                        </OverlayTrigger>
                    </div>

        </div>
    );
}

export default LessonUserForm;

//Отправляем POST запрос на сервер для сохранения урока
//axios.post(`http://localhost:4000/${user.sub}/saveLesson`, lessonData)

import Image from 'react-bootstrap/Image';
import listOfPoses850 from "../../myAssets/listOfPoses850.png"
import listOfPosesAll from "../../myAssets/listOfPosesAll.png"
import listOfPoses400 from "../../myAssets/listOfPoses400.png"

//react-responsive library
import { useMediaQuery } from 'react-responsive'; // импортируем хук для работы с медиа-запросами


const ImageListOfPoses = () =>{
    // определяем, является ли экран мобильным с шириной до 850px
    const isMobile = useMediaQuery({ maxWidth: 850 });
    // определяем, является ли экран мобильным с шириной до 550px
    const isVerySmallMobile = useMediaQuery({ maxWidth: 550 });

    return (
        <div>
            <Image src={isVerySmallMobile ? listOfPoses400 : isMobile ? listOfPoses850 : listOfPosesAll} alt="all asanas" fluid />

        </div>
    );
}
export default ImageListOfPoses;
import { FiInstagram } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa6";

const Footer = () => {
    return(
        <footer className="bg-light border-top ">
            <div className="d-flex flex-sm-row justify-content-center align-items-center py-4 mt-2" >
                <>
                    <a href="https://github.com/OlaCharn" target="_blank" rel="noopener noreferrer" className="me-1" >
                        <FaGithub  style={{ color: "black", width:"20px", height: "20px"}}/> 
                    </a>

                    <a href="https://www.linkedin.com/in/volha-charnukha/" target="_blank" rel="noopener noreferrer" className="me-1" >
                        <FaLinkedinIn style={{ color: "black", width:"20px", height: "20px"}}/> 
                    </a>

                    <a href="https://www.instagram.com/yogagirls_lesson_plan/" target="_blank" rel="noopener noreferrer" className="me-1" >
                        <FiInstagram style={{ color: "black", width:"20px", height: "20px"}}/> 
                    </a>
                    

                    <span className="FooterFont"> <a className="FooterFont" href="https://charnukha.de/" target="_blank" rel="noopener noreferrer"> <span >Volha Charnukha</span>  </a>   © 2024 All rights reserved. <a href="/privatepolicy" className="Privacy">Privacy Policy</a></span> 
                </>
            </div>
        </footer>
    )
}
export default Footer;
//Чтобы предотвратить переход устройства в режим сна или блокировки экрана во время работы приложения
// Web API - Screen Wake Lock API. 
//Этот API позволяет веб-приложениям запрашивать у устройства не входить в режим сна.
//запрос на блокировку экрана происходит только один раз при монтировании компонента, а разблокировка происходит при его размонтировании. 

import { useEffect } from 'react';

const ScreenWakeLock = () => {
    useEffect(() => {
        let wakeLock = null;

        const requestWakeLock = async () => {
            try {
                wakeLock = await navigator.wakeLock.request('screen');
                //console.log('Wake Lock is active');
            } catch (err) {
                //console.error('Could not create Wake Lock:', err);
            }
        };

        requestWakeLock();

        return () => {
            if (wakeLock) {
                wakeLock.release().then(() => console.log('Wake Lock was released'));
            }
        };
    }, []);

    return null; // Возвращаем null, потому что этот компонент не должен рендерить ничего на экране
};

export default ScreenWakeLock;
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
//import SecretTest from "./SecretTest";
import { Link } from "react-router-dom";

import { Dropdown } from "react-bootstrap";
import { TbUserCheck } from "react-icons/tb";


const LogoutButton = () => {
    const { logout, isAuthenticated } = useAuth0();

    return (
        isAuthenticated && (
        <div className="LogOutBattonAccountRender">
            <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <TbUserCheck />
                </Dropdown.Toggle>

                <Dropdown.Menu align="end" >
                    <Dropdown.Item as={Link} to="/useraccount" >Yogaboard</Dropdown.Item>
                    <Dropdown.Item onClick={() => logout({ returnTo: window.location.origin })}>
                        LOG OUT
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
                        {/*<SecretTest />*/}
        
        </div>
        )

    );
};

export default LogoutButton;


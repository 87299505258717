import { configureStore } from '@reduxjs/toolkit'
import yogaPoses from "./poseSlice"
import lesson from "./lessonSlice"



export const store = configureStore( {
    reducer: {
        yogaPoses: yogaPoses,
        lesson: lesson
    },
})

import "./loader.css"

const Loader = () =>{
    return(
        <div className="LoaderPosition">
            <div className="loader">
                <div className="loader-image"></div>
            </div>
        </div>
    )
}
export default Loader;
import ContactForm from "./ContactForm";
import Footer from "./Footer";
import Iam from "../../myAssets/Iam.jpg"
import { useTranslation } from "react-i18next"
import { HelmetProvider, Helmet } from 'react-helmet-async';

const About = () => {
    const { t, i18n } = useTranslation()


    return(
        <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
            <HelmetProvider>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('metatags.about.aboutTitle')}</title>
                <meta name="description" content={t('metatags.about.description')} />
            </Helmet>
            </HelmetProvider>


            <div className="container featureMargin" style={{ flex: "1" }}>
                <div className="row">

                    <div className="col-md-6 mb-5">
                        <img src={Iam} alt="pic_about" className="container-fluid " style={{width: "85%", display: "block", margin: "auto"}}/>
                    </div>

                    <div className="col-md-6">
                        <h3 className="FONT mb-5">
                            {t("about.adressingToAll")}
                        </h3>
                        <p className="FontSecond">
                            {t("about.par1")}
                        </p>
                        <p className="FontSecond">
                            {t("about.par2")}
                        </p>
                        <p className="FontSecond">
                            {t("about.par3")}
                        </p>
                        <p className="FONT mt-3">
                            Namaste.
                        </p>
                    </div>
                </div>
                <div className="featureMargin mb-5">
                <h3 className="pb-2 text-center FeatureHeader featureMargin"> {t("about.iWillBeGladTo")} </h3>
                <ContactForm />
                
                {/* <Donate /> */}
            </div>
            </div>

                <Footer />
        </div>
    )
}
export default About;
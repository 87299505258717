//функция для получения рандомной картинки из data.js


const RandomPoseImage = ({ data }) => {
    // Получаем случайный индекс для выбора случайного объекта из массива
    const randomIndex = Math.floor(Math.random() * data.length);
    // Выбираем случайный объект с позой
    const randomPose = data[randomIndex];
    // Получаем путь к изображению позы
    const imagePath = randomPose.img;

    return (
        <div>
            {/* Отображаем случайное изображение */}
            <img src={imagePath} alt="RandomPose" width="50px"  />
        </div>
    );
};

export default RandomPoseImage;

import { v4 as uuidv4 } from 'uuid';
import { createSlice } from '@reduxjs/toolkit'


// извлекаю данные из localStorage
const savedLessonPose = localStorage.getItem('lessonPose');
// Если данные есть в localStoragе , беру их
const initialState = savedLessonPose ? JSON.parse(savedLessonPose) : [];


export const lessonSlice = createSlice( {
    name: "lesson",
    initialState: {
        lessonPose: initialState,
    },

    reducers: {
        addPoseToLEsson: (state, action) =>{
            //console.log("addPoseToLEsson reducer called");
            //const timeId = new Date().getTime()
            state.lessonPose.push({
                id: uuidv4(), // Генерируем уникальный идентификатор с помощью uuid
                poseId: action.payload.asana.id, //тут asana.id см. Asana.js. Каждая поза - обьект и asana.id -это так называется и оттуда взяли. poseId -мы придумали
                duration: action.payload.asana.duration,
            })
        },
        removePoseFromLesson: (state, action) =>{
            //console.log("removePoseFromLesson reducer called");
            state.lessonPose = state.lessonPose.filter(
                lessonPose => lessonPose.id !== action.payload.lessonPoseId
            )
        },
        clearLesson: (state, action) =>{
            //console.log("clearLesson reducer called");
            state.lessonPose = []
        },

        // reducer для обновления duration
        addDuration: (state, action) => {
            //console.log("addDuration reducer called");
            const { lessonPoseId, duration } = action.payload;
            const index = state.lessonPose.findIndex(lessonPose => lessonPose.id === lessonPoseId); //Ищем индекс позы в массиве lessonPose, идентификатор которой (id) совпадает с lessonPoseId, полученным из action.payload. Метод findIndex вернет индекс первого элемента, который соответствует предоставленному условию. Если элемент не найден, вернется -1.
            if (index !== -1) {                                                             //Проверяем, найден ли элемент. Если index не равен -1, это означает, что элемент с заданным lessonPoseId был найден в массиве.
                state.lessonPose[index].duration = duration;                                  //Обновляем duration для найденной позы. Поскольку мы нашли индекс нужной позы в массиве, мы можем напрямую обратиться к нему и установить его duration на новое значение, полученное из action.payload
            }
        },

        //reducer для изменения положения при drag&drop
        reorderLessonPose: (state, action) =>{
            //console.log("reorderLessonPose reducer called");
            state.lessonPose = action.payload;
        },

        //reducer для local.Storage
        setLessonPoseFromLocalStorage: (state, action) => {
            //console.log("setLessonPoseFromLocalStorage reducer called");
            state.lessonPose = action.payload;
        },
        //reducer для установки поз из сохраненного урока
        setSavedLessonPoses: (state, action) => {
            // Прямая замена текущего состояния на новое, основанное на сохраненных позах
            state.lessonPose = action.payload.map(pose => ({
            id: uuidv4(), // Генерация нового уникального идентификатора для каждой позы
            poseId: pose.poseId, // Использование poseId из сохраненного урока
            duration: pose.duration, // Использование продолжительности из сохраненного урока
            }));
        },
    }
} )

//это логика общего времени
export const getTotalTime = state => {
    return Math.ceil(state.lesson.lessonPose.reduce((total, lessonPose) => {
        return total + lessonPose.duration;
    }, 0) / 60);
};


export const getLessonPose = state => state.lesson.lessonPose;
export const { addPoseToLEsson, removePoseFromLesson , clearLesson, addDuration, reorderLessonPose, setLessonPoseFromLocalStorage, setSavedLessonPoses} = lessonSlice.actions;
export default lessonSlice.reducer

        /*
        //reducer для сохраненных уроков
        setSavedLessonPoses: (state, action) => {
            const { payload: poseIds } = action;

             // Преобразуем массив ID поз в массив объектов поз
            state.lessonPose = poseIds.map(poseId => {
            const existingPose = state.lessonPose.find(p => p.poseId === poseId);
            if (existingPose) {
                return existingPose; // Если поза уже существует в lessonPose, оставляем ее без изменений
            }
            return {
                id: uuidv4(), // Генерируем уникальный идентификатор с помощью uuid
                poseId,
                duration: 30,
            };
            });
        },
        */

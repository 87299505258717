import { useState } from "react";
import { data } from "../data"
import "../App.css"
import { useTranslation } from "react-i18next"



import { useSelector } from "react-redux";
import { getSelectedLevel } from "../redux/poseSlice";

import ShowAllLevels from "./Filters/ShowAllLevels";
import YogaCards from "../Components/YogaCards";

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsSearch } from "react-icons/bs";
import ScrollToTopButton from "./Website/ScrollToTopButton";
import TabsYG from "./Website/TabsYG";
import { HelmetProvider, Helmet } from 'react-helmet-async';



function SearchAsana(){
    //searchbar
    const [searchWord, setSearchWord] = useState("")

    const selectedLevels = useSelector(getSelectedLevel)

    const filteredData = data.filter((dataItem) => {
        const usersWord = searchWord.toLowerCase();
        const isLevelMatch = selectedLevels === "All Levels" || dataItem.level === selectedLevels;
        const isNameMatch = dataItem.asanasName.toLowerCase().includes(usersWord) 
                        || dataItem.asanasNameRus.toLowerCase().includes(usersWord)
                        || dataItem.asanasNameSanskrit.toLowerCase().includes(usersWord) 
                        || dataItem.asanasNameDe.toLowerCase().includes(usersWord) 
                        || dataItem.asanasNameBe.toLowerCase().includes(usersWord) ;

    
        return isLevelMatch && isNameMatch;
    });

    const { t, i18n } = useTranslation()

    
    return(
        <div>
            <HelmetProvider>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('metatags.searchasana.searchasanaTitle')}</title>
                <meta name="description" content={t('metatags.searchasana.description')} />
            </Helmet>
            </HelmetProvider>

            <div >
                <TabsYG />           
            </div>

            <InputGroup className='container-fluid my-4'>
                <InputGroup.Text id="inputGroup-sizing-default">
                    <BsSearch />
                </InputGroup.Text>
                    <Form.Control
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    type = "text"
                    placeholder={t("searchAsanas.placeholder")}
                    className="search_input"
                    onChange={ (event) => setSearchWord(event.target.value)}
                    />
            </InputGroup>

            <ShowAllLevels />

            <YogaCards yogaCardProp = {filteredData} />

            <ScrollToTopButton />
        </div>
    )
}
export default SearchAsana
//это компонент для проверки идентификации пользователя.
//он сделан, как обертка. Т.е. использовать надо как <AuthenticatedNavLink> НАША КНОПКА например </AuthenticatedNavLink>


import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useTranslation } from "react-i18next"


function AuthenticatedNavLink({ to, children }) {
    const { isAuthenticated } = useAuth0();
    const [showTooltip, setShowTooltip] = useState(false);

    const { t } = useTranslation()


    const handleClick = event => {
        if (!isAuthenticated) {
            event.preventDefault(); // Предотвратить переход, если не авторизован
            setShowTooltip(true);  // Показать tooltip
            setTimeout(() => setShowTooltip(false), 3000); // Скрыть tooltip через 3 секунды
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("tooltip.auth")}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            show={showTooltip}
            placement="top"
            overlay={renderTooltip}
        >
            <NavLink to={to} onClick={handleClick} className="nav-link">
                {children}
            </NavLink>
        </OverlayTrigger>
    );
}

export default AuthenticatedNavLink;
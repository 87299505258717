import { useDispatch } from "react-redux"
import { addDuration } from "../../redux/lessonSlice";
import { useEffect, useRef, useState } from "react";
import { Overlay, Tooltip } from 'react-bootstrap';

//icons
import { IoTimerOutline } from "react-icons/io5";
//react-responsive library
import { useMediaQuery } from 'react-responsive'; // импортируем хук для работы с медиа-запросами
//translation
import { useTranslation } from "react-i18next"



const AddDurationPose = ( { lessonPoseId, initialDuration } ) =>{
    const [inputValue, setInputValue] = useState(initialDuration.toString());  //user вводит число, обновляется inputValue
    const [showTooltip, setShowTootip] = useState(false); //тултип
    const target = useRef(null); // Реф для цели тултипа
    const dispatch = useDispatch(); //redux изменение состояния

    useEffect(()=> {
        setInputValue(initialDuration.toString());
    }, [initialDuration])

    //скрыть тултип после 2 сек после появления
    useEffect(() => {
        let timeout;
        if (showTooltip) {
            timeout = setTimeout(() => {
                setShowTootip(false);
            }, 2000);
        }
        return () => clearTimeout(timeout);
    }, [showTooltip]);

    const handleDurationChange = (e) => {
        const inputText = e.target.value;
        const isValidInput = /^\d*$/.test(inputText); // Регулярное выражение пропускает только цифры и пустую строку
        const shouldShowTooltip = inputText === "0" || inputText === ""; // Показывать тултип, если значение "0" или пустая строка        
        setShowTootip(shouldShowTooltip);

        if (isValidInput){
            setInputValue(inputText);
            if (inputText !== "" ) {
                const newDuration = inputText === '' ? 0 : parseInt(inputText, 10); //<-- вместо унарного плюса +inputValue
                dispatch(addDuration({ lessonPoseId, duration: newDuration }))
                }
        } 
    }

    // определяем, является ли экран мобильным
    const isMobile = useMediaQuery({ maxWidth: 820 }); 

    //translation
    const { t } = useTranslation()


    return(
        <div  >
            <span className="AddDurationTimeFontSize">
                {isMobile ? <IoTimerOutline /> : ( <> <IoTimerOutline /> {t("lesson.duration")} </> )  }
                </span>  
            <input
                type="text"
                value={inputValue}
                onChange={handleDurationChange}
                min="1" //чтобы не было отрицательных чисел
                maxLength={5}
                ref={target}
                className="addDuration shadow-sm "
            />
            <span className="AddDurationSecFontSize"> {t("lesson.sec")}</span>

            <Overlay target={target.current} show={showTooltip} placement="top"  >
                {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                        {t("tooltip.duration")}
                    </Tooltip>
                )}
            </Overlay>        
            
        </div>
    )
}
export default AddDurationPose;

/*
        if (isValidInput){
            setInputValue(inputText);
            const newDuration = inputText === '' ? 0 : parseInt(inputText, 10); //<-- вместо унарного плюса +inputValue
            dispatch(addDuration({ lessonPoseId, duration: newDuration }))
        }

После проверки, что введенный текст состоит только из цифр, 
происходит вызов dispatch(addDuration(...)), который обновляет состояние в Redux. 
Значение duration передается в Redux как newDuration, 
и оно предварительно преобразуется в число с использованием parseInt(inputText, 10). 
Это число затем используется в вашем Redux-состоянии. */
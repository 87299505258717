import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next"
import "./website.css"
import Features from './Features';
import ImageListOfPoses from './ImageListOfPoses';
import { Link } from "react-router-dom";
import  ScrollToTopButton  from "./ScrollToTopButton"
import Footer from './Footer';
import ContactForm from './ContactForm';
import { HelmetProvider, Helmet } from 'react-helmet-async';



const Homepage = () =>{

    const { t, i18n } = useTranslation()

    return (
        <main>
            {/* <div>
                <p>{t("WelcomeMessage")}</p>
            </div>
            */}
            <HelmetProvider>
            <Helmet>
                <html lang={i18n.language} />
                <title>{t('metatags.home.homeTitle')}</title>
                <meta name="description" content={t('metatags.home.description')} />
                <meta name="keywords" content={t('metatags.home.keywords')} />
            </Helmet>
            </HelmetProvider>
            
            <div className='headline container-fluid  '>
                <div className='headlineWords'>
                    <h1 className='h1MainFont'>YOGA</h1>
                    <p className='h2MainFont'>SEQUENCE</p>
                    <p className='h2MainFont Builder'>BUILDER</p>
                </div>

                <div className='headlineButtonAndVideo'>
                    <video autoPlay loop muted style={{ width: '300px' }} className="headlineVideo">
                        <source src={require("../../myAssets/ygVideo2.mp4")} type="video/mp4" />
                    </video>
                    <Link to="/searchasana" className='headlineButton FontSecond' >
                        <Button  size="lg" variant='dark' className='visualHeadlineButton  '> {t("homepage.startButton")} </Button>
                    </Link>
                </div>
            </div>

            <div>
                <ImageListOfPoses/>
            </div>
            <div >
                <Features />
            </div>

            <div className='mb-5 mt-5'>
                <ImageListOfPoses/>
            </div>

            <div className='mb-5 mt-5 container-fluid '>
                <h2 className="pb-2 text-center FeatureHeader featureMargin"> {t("homepage.contacts")} </h2>
                <ContactForm />
            </div>

            <ScrollToTopButton />
            
            <Footer className='mt-5'/>
            

        </main>
    );
};
export default Homepage


